.service-main {
    padding-top: 80px;
    padding-bottom: 80px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.intro-row  {
    display: grid;
    grid-template-columns: 1fr 410px;
    grid-column-gap: 30px;
    margin-bottom: 80px;

    .text {
        max-width: 630px;

        .title {
            font-size: 44px;
            line-height: 54px;
            color: #FFFFFF;
            margin-bottom: 60px;
        }

        p {
            font-size: 18px;
            line-height: 26px;
            color: #FFFFFF;

            & + P {
                margin-top: 20px;
            }
        }
    }

    .get-consult {
        margin-top: -80px;
    }
}

.jobs-types > .title {
    color: #FFFFFF;
    margin-bottom: 60px;
}

.jobs-type-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;

    .ic {
        margin-bottom: 20px;
        width: 48px;
        height: 48px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    strong {
        display: block;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 20px;
        color: #FFFFFF;
    }

    p {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
    }
}

.steps-section {
    padding-top: 80px;
    padding-bottom: 80px;
}

.steps-list  li:not(:last-child) {
    margin-bottom: 60px;
}

.step-row {
    display: grid;
    grid-template-columns: 190px 1fr;
    grid-column-gap: 30px;

    .num {
        font-weight: normal;
        font-size: 80px;
        line-height: 64px;
        color: $accent;
    }

    .text strong {
        display: block;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: #1F1F1F;
        margin-bottom: 15px;
    }

    .text p {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #1F1F1F;
    }
}

.docs-slider-container {
    overflow-x: hidden;
}

.jobs-type-list-slider .swiper-pagination {
    display: none;
}


@media (min-width: calc($tb + 1px)) and (max-width: 1380px) {
    .docs-slider .sld-nav-btn-prev, .docs-slider .sld-nav-btn-next {
        transform: translateX(0);
    }
}


@media (max-width: $tb) {
    .service-main {
        padding-top: 60px;
        padding-bottom: 60px;

        .wrapper {
            padding-right: 0;
        }
    }

    .intro-row .get-consult {
        margin-top: -60px;
    }

    .intro-row .text .title {
        font-size: 36px;
        line-height: 48px;
        margin-bottom: 40px;
    }

    .intro-row {
        display: flex;
        justify-content: space-between;
        grid-column-gap: 0;

        .text {
            max-width: 512px;
            width: 100%;
            margin-right: 30px;
        }
    }


    .intro-row {
        margin-bottom: 60px;
    }

    .jobs-types > .title {
        margin-bottom: 40px;
    }

    .jobs-types {
        padding-right: 60px;
    }

    .jobs-type-list strong {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .jobs-type-list p {
        font-size: 14px;
        line-height: 20px;
    }

    .steps-section {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .step-row {
        grid-template-columns: 126px 1fr;
    }

    .step-row .text strong {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 15px;
    }

    .steps-list li:not(:last-child) {
        margin-bottom: 40px;
    }

    
}


@media (max-width: $mb) {
    .service-main .wrapper {
        padding-right: 40px;
    }

    .intro-row {
        flex-direction: column;
    }

    .intro-row .get-consult {
        margin-top: 30px;
    }

    .intro-row .text {
        max-width: 100%;
        margin-right: 0;
    }

    .intro-row {
        margin-bottom: 40px;
    }

    .jobs-type-list {
        grid-template-columns: 100%;
        grid-row-gap: 40px;

        li {
            display: flex;

            .ic {
                width: 48px;
                min-width: 48px;
                height: 48px;
                margin-right: 30px;
            }
        }
    }

    .jobs-types {
        padding-right: 0;
    }

    .step-row {
        grid-column-gap: 0;
    }

    


}

@media (max-width: $mx) {

    .service-main .wrapper {
        padding-right: 20px;
    }


    .service-main {
        padding-top: 30px;
        padding-bottom: 36px;
    }

    .intro-row .text .title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 30px;
    }

    .intro-row .text p {
        font-size: 16px;
        line-height: 24px;
    }

    .intro-row .get-consult {
        margin-top: 40px;
    }

    .jobs-type-list-slider {
        padding-bottom: 44px;
    }

    .jobs-type-list {
        display: flex;
        grid-template-columns: none;
        grid-row-gap: 0;  
        grid-column-gap: 0; 
    }

    .jobs-type-list-slider .swiper-pagination {
        bottom: 0 !important;
    }

    .jobs-type-list li {
        flex-direction: column;
    }

    .jobs-types > .title {
        margin-bottom: 30px;
    }

    .jobs-type-list strong {
        font-size: 16px;
        line-height: 22px;
    }

    .steps-section {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .step-row {
        grid-template-columns: 100%;
        grid-row-gap: 20px;
    }

    .step-row .num {
        font-size: 60px;
        line-height: 40px;
    }

    .step-row .text p {
        font-size: 14px;
        line-height: 20px;
    }

    .jobs-type-list-slider .swiper-pagination {
        display: block;
    }
}