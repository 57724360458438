.news-page {
    padding-top: 80px;
    padding-bottom: 100px;

    .title {
        margin-bottom: 60px;
    }
}

.news-tiles {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 60px;
}


.news-grid  + .news-tiles {
    margin-top: 60px;
}


@media (max-width: $tb) {
    .news-page {
        padding-top: 60px;
        padding-bottom: 80px;
    }

    .news-page .title {
        margin-bottom: 50px;
    }

    .news-grid + .news-tiles {
        margin-top: 40px;
    }

    .news-tiles {
        grid-row-gap: 40px;
    }
}


@media (max-width: $mb) {


    .news-tiles {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 40px;
    }

    .news-page .news-grid li:nth-child(1) {
        width: 100%;
        margin-bottom: 20px;
    }

    .news-grid + .news-tiles {
        margin-top: 30px;
    }

}


@media (max-width: $mx) {
    .news-page {
        padding-top: 28px;
        padding-bottom: 60px;
    }

    .news-page .title {
        margin-bottom: 30px;
    }

    .news-tiles {
        grid-template-columns: 100%;
        grid-row-gap: 20px;
    }

    .news-page .news-grid li:nth-child(1) {
        margin-bottom: 0;
    }
}