.main-slider {
    background-color: rgba(31, 31, 31, 0);
}

.main-slider .swiper-slide {
    position: relative;
    padding-top: 60px;
    padding-bottom: 116px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;


    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(31, 31, 31, 0.95) 0%, rgba(31, 31, 31, 0.7) 43.23%, rgba(31, 31, 31, 0) 100%);
    }

    & > * {
        position: relative;
        z-index: 1;
    }
}


.slide-content {
    max-width: 410px;

    .title {
        display: block;
        font-weight: bold;
        font-size: 32px;
        line-height: 44px;
        color: #FFFFFF;
        margin-bottom: 30px;

        sup {
            display: inline-block;
            transform: translateY(5px);
        }
    }

    p {
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        color: #FFFFFF;
    }

    .more-btn {
        margin-top: 30px;
    }
}

.main-slider {
    position: relative;
}

.main-slider .nav-row  {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 12;
    

    .wrapper {
        display: flex;
        justify-content: flex-end;
    }
}


.swiper-pagination {
    width: fit-content !important;
    position: absolute;
    left: 50% !important;
    transform: translateX(-50%) !important;
    bottom: 30px !important;
    z-index: 13;

    .swiper-pagination-bullet {
        margin: 0 14px !important;
        background: #FFFFFF;
        width: 8px;
        height: 8px;
        opacity: 1;

        &.swiper-pagination-bullet-active {
            background: $accent;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 16px;
                height: 16px;
                background-color: $accent;
                border-radius: 50%;
            }
        }
    }
}


@media (max-width: $tb) {
    .slide-content .title {
        font-size: 28px;
        line-height: 40px;
    }


    .slide-content {
        max-width: 437px;
    }

    .slide-content p {
        font-size: 16px;
        line-height: 24px;
    }


    .main-slider .swiper-slide {
        padding-bottom: 158px;
    }

    .swiper-pagination .swiper-pagination-bullet {
        margin: 0 9px !important;
    }

   .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
        width: 12px;
        height: 12px;
    }

    .main-slider .swiper-pagination {
        bottom: 28px !important;
    }

}


@media (max-width: $mx) { 

    .slide-content .title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 10px;
    }

    .main-slider .swiper-slide {
        padding-top: 30px;
    }

    .slide-content p {
        font-size: 14px;
        line-height: 20px;
    }

    .slide-content .more-btn {
        margin-top: 18px;
    }

    .main-slider .more-btn .ic {
        width: 48px;
        height: 48px;
        min-width: 48px;
    }

    .main-slider .swiper-slide {
        min-height: 500px;
    }

    .nav-button {
        padding: 14px;
    }

    .main-slider .swiper-pagination {
        bottom: 18px !important;
    }

    body .main-slider .swiper-pagination {
        left: 15px !important;
        transform: none !important;
        transform: none;
    }

}