.about-company {
    padding-top: 80px;
    padding-bottom: 80px;
    overflow: hidden;

    .wrapper {
        // display: grid;
        // grid-template-columns: 480px 1fr;
        // grid-column-gap: 100px;
        display: flex;
        align-items: center;
    }
}

.about-company-text-content {
    max-width: 480px;
    width: 100%;
    margin-right: 100px;
    position: relative;
    z-index: 2;
}


.about-company-text-content .title {
    
    margin-bottom: 30px;
}

.about-company-text-content p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;

    & + p {
        margin-top: 15px;
    }
}


.about-company-text-content .more-btn {
    margin-top: 30px;
}

#circle {
    --circleSize: 710px;
}

#circle  {
    position: relative;
    height: var(--circleSize);
    width: var(--circleSize);
    min-width: var(--circleSize);
}

.circle {
    width: 120px;
    height: 120px;
    position: absolute;
    background-color: $accent;
}


#circle {

    & > * {
        z-index: 2;
    }
 
    .center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + (139px * 2));
        height: calc(100% + (139px * 2));
        background: #F5F6F6;
        opacity: 0.5;
        border-radius: 50%;
        z-index: 1;
    }

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + (279px * 2));
        height: calc(100% + (279px * 2));
        background: #F5F6F6;
        opacity: 0.3;
        border-radius: 50%;
    }


    .dashed-circle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 2px dashed $accent;
        width: calc(100% - 120px);
        height: calc(100% - 120px);
        border-radius: 50%;
        z-index: 3 !important;
    }
}

.lg {
    position: absolute;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    mix-blend-mode: normal;
    border-radius: 10px;
    z-index: 4 !important;
}

.lg:nth-child(1) {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.lg:nth-child(2) {
    top: 120px;
    left: calc(100% - 75px);
    transform: translateX(-100%);
}


.lg:nth-child(3) {
    top: 50%;
    left: 100%;
    transform: translate(-100%, -50%);
}

.lg:nth-child(4) {
    bottom: 120px;
    left: calc(100% - 75px);
    transform: translateX(-100%);
}


.lg:nth-child(5) {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.lg:nth-child(6) {
    bottom: 120px;
    left: 75px;
}


.lg:nth-child(7) {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}


.lg:nth-child(8) {
    top: 120px;
    left: 75px;
}


@media (max-width: 1124px) {
    .about-company .wrapper {
        flex-direction: column;
    }

    .about-company-text-content {
        max-width: 100%;
        margin-right: 0;
    }

    #circle {
        --circleSize: 580px;
    }

    .lg {
        width: 100px;
        height: 100px;

        img {
            max-width: 80%;
        }
    }

    #circle .dashed-circle {
        width: calc(100% - 100px);
        height: calc(100% - 100px);
    }

    .lg:nth-child(2) {
        top: 100px;
        left: calc(100% - 60px);
    }

    .lg:nth-child(4) {
        bottom: 100px;
        left: calc(100% - 60px);
    }

    .lg:nth-child(6) {
        bottom: 100px;
        left: 60px;
    }

    .lg:nth-child(8) {
        top: 100px;
        left: 60px;
    }

    .about-company {
        padding-top: 60px;
        padding-bottom: 40px;
    }

    #circle:before {
        width: calc(100% + (110px * 2));
        height: calc(100% + (110px * 2));
    }

    #circle:after {
        width: calc(100% + (160px * 2));
        height: calc(100% + (160px * 2));
    }

    #circle .center {
        width: 166px;
    }
}


@media (max-width: $mx) { 

    .about-company {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .about-company-text-content .title {
        margin-bottom: 20px;
    }

    .about-company-text-content .more-btn {
        margin-top: 20px;
    }
    

    #circle {
        --circleSize: 320px;
    }

    .lg {
        width: 68px;
        height: 68px;
    }

    .about-company-graph {
        margin-top: 40px;
    }


    .lg:nth-child(2) {
        top: 38px;
        left: calc(100% - 38px);
    }

    .lg:nth-child(8) {
        top: 38px;
        left: 38px;
    }

    .lg:nth-child(4) {
        bottom: 38px;
        left: calc(100% - 38px);
    }

    .lg:nth-child(6) {
        bottom: 38px;
        left: 38px;
    }

    #circle .dashed-circle {
        width: calc(100% - 68px);
        height: calc(100% - 68px);
    }

    #circle .center {
        width: 100px;
    }

    #circle:before {
        width: calc(100% + (60px * 2));
        height: calc(100% + (60px * 2));
    }

    #circle:after {
        width: calc(100% + (120px * 2));
        height: calc(100% + (120px * 2));
    }
}