.sertificates {
    padding-top: 80px;
    padding-bottom: 80px;
    background: #F5F6F6;

    .wrapper > .title {
        margin-bottom: 60px;
    }
}

.docs-slider {
    overflow: visible !important;

    .sld-nav-btn {
        top: 44.5%;
    }
}

.docs-slider .swiper-slide  {
    display: block;
    position: relative;
    max-width: 410px;
    width: 100%;
    background-color: #fff;
    padding-left: 30px;
    padding-right: 30px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.file-type-lbl {
    position: absolute;
    top: 30px;
    left: 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 4px 10px;
    background-color: $accent;
}

.docs-slider-container {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: calc(var(--indentToViewport) - 30px);
        background: linear-gradient(270deg, #F5F6F6 0%, rgba(245, 246, 246, 0) 100%);
        z-index: 2;
    }

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: calc(var(--indentToViewport) - 30px);
        background: linear-gradient(270deg, #F5F6F6 0%, rgba(245, 246, 246, 0) 100%);
        transform: matrix(-1, 0, 0, 1, 0, 0);
        z-index: 2;
    }
}

@media (max-width: $tb) {
    .docs-slider .sld-nav-btn-next {
        right: 0;
    }

    .docs-slider .sld-nav-btn-prev {
        left: 0;
    }

    .docs-slider .swiper-slide{
        max-width: 100%;
    }

    .docs-slider-container:after {
        width: calc(var(--indentToViewport) - 15px);
    }

    .docs-slider-container:before {

        width: calc(var(--indentToViewport)  - 15px);
    }

    .sertificates {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .sertificates .wrapper > .title {
        margin-bottom: 40px;
    }

    .file-type-lbl {
        padding: 4.26px 10.66px;
    }
}

@media (max-width: $mb) {
    .docs-slider .swiper-slide {
        max-height: 407px;
    }

    .file-type-lbl {
        top: 25px;
        padding: 3.36px 8.15px;
        font-size: 13.0341px;
        line-height: 20px;
    }
}


@media (max-width: $mx) {
    .sertificates {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .sertificates .wrapper > .title {
        margin-bottom: 20px;
    }

    .docs-slider {
        padding-bottom: 73px;
    }

    .docs-slider .sld-nav-btn-next {
        right: 0;
        top: auto;
        bottom: 0;
        transform: none;
    }

    .docs-slider .sld-nav-btn-prev {
        left: auto;
        right: 64px;
        top: auto;
        bottom: 0;
        transform: none;
    }
}