.reviews {
    padding-top: 40px;
    padding-bottom: 100px;
    overflow-x: hidden;

    .wrapper > .title {
        margin-bottom: 80px;
    }
}

.review-card  {
    padding: 30px;
    background: #F5F6F6;
    display: grid;
    grid-template-columns: 260px 1fr;
    grid-column-gap: 40px;

    .rc-content  {
        padding-top: 35px;

        .title {
            display: block;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: #1F1F1F;
            margin-bottom: 30px;
        }

        p {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #1F1F1F;

            & + p {
                margin-top: 21px;
            }
        }
    }
}

.reviews-slider {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    overflow: visible !important;
    position: relative;

    .swiper-slide .review-card {
        transform: scale(0.75);
        transition: transform .3s ease-in;
        will-change: transform;
    }

    .swiper-slide-active .review-card {
        transform: scale(1);
    }

    .swiper-slide-prev .review-card {
        transform-origin: right;
    }

    .swiper-slide-next .review-card {
        transform-origin: left;
    }
}

.sld-nav-btn {
    position: absolute;
    top: 43%;
    transform: translateY(-50%);
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background-color: $accent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    &-prev {
        left: 0;
        transform: translateX(-50%);
    }

    &-next {
        right: 0;
        transform: translateX(50%);
    }
}

.reviews-slider .swiper-pagination {
    display: none;
}


@media (max-width: $tb) { 

    .reviews {
        padding-top: 30px;
        padding-bottom: 60px;
    }

    .reviews .wrapper > .title {
        margin-bottom: 40px;
    }

    .reviews-slider {
        max-width: 592px;
    }

    .review-card .rc-content .title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 30px;
    }

    .review-card .rc-content p {
        font-size: 14px;
        line-height: 20px;
    }

    .review-card .rc-content p + p {
        margin-top: 10px;
    }

    .review-card {
        padding: 20px;
        grid-template-columns: 260px 1fr;
        grid-column-gap: 30px;
    }

    .review-card .rc-content {
        padding-top: 31px;
    }

    .reviews-slider .swiper-slide:not(.swiper-slide-active) .review-card {
        transform: scale(0.632);
    }

    .sld-nav-btn-prev {
        left: -22%;
    }

    .sld-nav-btn-next {
        right: -22%;
    }

    

}


@media (max-width: $mb) {
    .reviews .wrapper > .title {
        margin-bottom: 30px;
    }

    .sld-nav-btn-prev {
        left: -21px;
    }

    .sld-nav-btn-next {
        right: -21px;
    }
}


@media (max-width: $mx) {
    body .reviews-slider {
        max-width: none;
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
    }

    .reviews-slider .swiper-slide .review-card {
        padding: 20px 20px 30px;
        display: block;

    }

    .reviews-slider .sld-nav-btn {
        display: none;
    }

    .rc-image {
        height: 200px;
        width: 100%;
        background-color: #fff;

        img {
            height: 100%;
            width: 100%;
            object-fit: scale-down;
        }
    }

    .review-card .rc-content {
        padding-top: 30px;
    }

    .review-card .rc-content .title {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .reviews .wrapper > .title {
        margin-bottom: 20px;
    }

    body .reviews-slider {
        padding-bottom: 43px;
    }

    .reviews-slider .swiper-pagination {
        display: block;
        bottom: 0 !important;
        left: 50%;
        transform: translateX(-50%);

        .swiper-pagination-bullet {
            background-color: #F5F6F6;
        }
    }

    .reviews-slider .swiper-slide .review-card {
        transform: scale(1) !important;
    }


    .reviews {
        padding-bottom: 38px;
    }
}