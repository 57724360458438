.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transition: transform .3s ease 0;

    &.active {
        opacity: 1;
            visibility: visible;
        transition: transform .1s ease .4;

        .modal-inner  {
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, 0);
        }
    }
}

.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(31, 31, 31, 0.4);
    backdrop-filter: blur(3px);
    z-index: 1;
}

.modal-inner {
    position: absolute;
    top: 20.53%;
    left: 50%;
    transform: translate(-50%, 50px);
    max-width: 850px;
    width: 100%;
    padding: 60px 60px 0 60px;
    background: #fff;
    transition: all .3s ease .3s;
    will-change: transform;
    opacity: 0;
    visibility: hidden;
    z-index: 2;
    

    
    

    .submit-btn {
        margin-top: 60px;
    }

    .form-inputs {
        margin-bottom: 42px;
    }
}

.modal-title {
    margin-bottom: 60px;
}

.modal-close  {
    position: absolute;
    top: 0;
    right: 0;
    width: 54px;
    height: 54px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(230, 42, 42, 0.1);
    border-radius: 0px 0px 0px 30px;
}

@media (max-width: $tb) {

    .modal {
        display: flex;
        justify-content: center;
        overflow-y: auto;
        padding-bottom: 60px;
    }

    .modal-overlay {
        position: fixed;
    }

    .modal-inner {
        margin-top: 134px;
        position: relative;
        top: auto;
        left: auto;
        transform: translate(0, 50px);
        height: fit-content;
        max-width: 748px;
        padding-top: 50px;
    }

    .modal.active .modal-inner {
        transform: translate(0, 0);
    }

    .modal-title {
        margin-bottom: 40px;
    }

    .modal-inner .submit-btn {
        margin-top: 40px;
    }
    
}

@media (max-width: $mb) {
    .modal {
        z-index: 100;
    }

    .modal-inner {
        margin-top: 34%;
        width: calc(100% - 80px);
        max-width: none;
        padding-top: 60px;
    }

    .modal-title {
        margin-bottom: 30px;
    }
}


@media (max-width: $mx) {
    .modal {
        padding-bottom: 0;
        overflow-y: hidden;
    }

    .modal-inner {
        display: flex;
        flex-direction: column;
        margin-top: 0;
        width: 100%;
        height: 100%;
        padding-top: 60px;
        overflow-y: auto;
        padding: 40px 20px 0 20px;
        transform: translate(0, 10px);

        .callback-form {
            flex: 1;
        }
    }

    .modal-inner .check-block {
        margin-bottom: 40px;
    }

    .modal-inner .submit-btn {
        margin-top: auto;
    }

    .modal-title {
        margin-bottom: 70px;
    }

    .modal-inner .form-inputs {
        margin-bottom: 30px;
    }

    .modal-inner .raunded-btn {
        padding: 16px 40px;
    }

    .modal-close {
        width: 48px;
        height: 48px;
    }
}