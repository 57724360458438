.all-brands-page  .page-title {
    margin-bottom: 60px;
}

.brands-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
}

.brand-card  {
    padding: 40px;
    background: #F5F6F6;
    height: 100%;
}

.brand-card  .brand-card-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    

    .brand-logo {
        width: 120px;
        height: 120px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            mix-blend-mode: darken;
        }
    }
}

.brand-card  .brand-card-body {
    padding-top: 20px;

    p {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #1F1F1F;

        & + p {
            margin-top: 20px;
        }
    }

    ul {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    ul li {
        position: relative;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #1F1F1F;
        padding-left: 37px;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        &::before {
            content: "";
            position: absolute;
            top: 8px;
            left: 5px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $accent;
        }
        
    }
    
}


@media (max-width: $tb) {
    .all-brands-page .page-title {
        margin-bottom: 50px;
    }

    .brand-card {
        padding: 30px;
    }

    .brand-card .brand-card-head .brand-logo {
        width: 100px;
        height: 100px;
    }
}


@media (max-width: $mb) {
    .brands-list {
        grid-template-columns: 100%;
        grid-row-gap: 10px;
    }

    .brands-list  {
        width: calc(100% + 80px);
        margin-left: -40px;
        margin-right: -40px;
    }

    .brand-card {
        padding: 20px 40px 30px 40px;
    }

    .all-brands-page {
        padding-bottom: 80px;
    }
}

@media (max-width: $mx) {
    .brands-list  {
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
    }
    
    .brand-card {
        padding: 20px;

        .more-btn .ic {
            width: 48px;
            min-width: 48px;
            height: 48px;
        }
    }

    .all-brands-page .page-title {
        margin-bottom: 30px;
    }

    .brand-card .brand-card-head .brand-logo {
        width: 80px;
        height: 80px;
    }

    .brand-card .brand-card-body p {
        font-size: 14px;
        line-height: 20px;
    }

    .brand-card .brand-card-body p + p {
        margin-top: 10px;
    }

    .all-brands-page {
        padding-bottom: 60px;
    }

    .brand-card .brand-card-body ul li {
        font-size: 14px;
        line-height: 20px;
        padding-left: 31px;
    }
}








