.catalog-page  .page-title {
    margin-bottom: 60px;
}

.catalog-page {
    padding-top: 80px;
    padding-bottom: 100px;
}

.catalog-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 60px;
}

.catalog-card-item {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0, #F5F6F6 46.88%, rgba(245, 246, 246, 0) 100%);
    }

    & > *:not(.catalog-card-item-photo) {
        position: relative;
        z-index: 1;
    }

    .catalog-card-item-photo {
        height: 420px;

        img {
            width: 100%;
            object-fit: contain;
        }
    }

    .catalog-card-item-body {
        margin-top: -40px;
    }

    .cat-link {
        margin-bottom: 40px;
        justify-content: space-between;

        span {
            font-size: 24px;
            line-height: 32px;
        }
    }

    .catalog-card-item-body {
        padding: 0 40px 40px 40px;
    }

    // .catalog-card-item-cols {
    //     grid-template-columns: repeat(2, 1fr);
    //     grid-column-gap: ;
    // }

    .catalog-card-item-cols  {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 30px;
    }

    .catalog-card-item-col .cat-level-2 {
        display: block;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: #1F1F1F;

    }

    .catalog-card-item-col > .cat-level-2  {
        margin-bottom: 30px;
    }

    

    .catalog-card-item-col ul li:not(:last-child) {
        margin-bottom: 15px;
    }

    .catalog-card-item-col ul li a {
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #1F1F1F;
    }
 
}

.callback-section--mg  {
    margin-top: 80px;
    margin-bottom: 80px;
}

.personal-selection .form-inputs {
    margin-bottom: 60px;
}

.catalog-card-item-photo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


@media (max-width: 1260px) {
    .catalog-card-item .catalog-card-item-col .cat-level-2 {
        font-size: 20px;
        line-height: 28px;
    }

    .catalog-card-item .catalog-card-item-col ul li a {
        font-size: 18px;
        line-height: 24px;
    }
}


@media (max-width: 1220px) {
    .catalog-card-item .catalog-card-item-cols {
        grid-template-columns: 0;
        grid-row-gap: 0;
        display: flex;
        flex-direction: column;

        .catalog-card-item-col:not(:last-child) {
            margin-bottom: 20px;
            height: fit-content;
        }
    }
}

@media (max-width: $tb) {
    .catalog-page {
        padding-top: 60px;
        padding-bottom: 80px;
    }

    .catalog-page .page-title {
        margin-bottom: 50px;
    }

    .catalog-card-item .catalog-card-item-photo {
        height: 290px;
    }

    .catalog-card-item .catalog-card-item-body {
        padding: 0 30px 30px 30px;
    }

    .catalog-card-item .cat-link {
        margin-bottom: 20px;
    }

    .catalog-card-item .cat-link span {
        font-size: 20px;
        line-height: 28px;
    }

    .catalog-card-item .catalog-card-item-body {
        margin-top: -20px;
    }

    .catalog-card-item .catalog-card-item-col .cat-level-2 {
        font-size: 18px;
        line-height: 24px;
        
    }

    .catalog-card-item .catalog-card-item-col > .cat-level-2  {
        margin-bottom: 14px;
    }

    .catalog-card-item .catalog-card-item-col ul li a {
        font-size: 14px;
        line-height: 20px;
    }

    .catalog-card-item .catalog-card-item-col ul li:not(:last-child) {
        margin-bottom: 10px;
    }

    .callback-section--mg {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .personal-selection .form-inputs {
        margin-bottom: 40px;
    }

    .personal-selection:not(.callback-section--t2) .wrapper .callback-section-title-col p {
        max-width: 380px;
    }

    .catalog-card-item .catalog-card-item-col ul li:not(:last-child) .cat-level-2 {
        margin-bottom: 20px;
    }

    .catalog-grid {
        grid-column-gap: 30px;
        grid-row-gap: 40px;
    }

    .catalog-card-item .cat-link span {
        font-weight: bold;
    }
}


@media (max-width: $mb) {
    .catalog-grid {
        display: grid;
        grid-template-columns: 100%;
        grid-row-gap: 20px;
    }

    

    .callback-section--mg {
        margin-top: 60px;
        margin-bottom: 40px;
    }

    .personal-selection:not(.callback-section--t2) .wrapper .callback-section-title-col p {
        max-width: 100%;
    }

    .personal-selection:not(.callback-section--t2) .wrapper {
        grid-template-columns: 100%;
        grid-row-gap: 40px;
    }

}


@media (min-width: $mx) and (max-width: $mb) {
    .catalog-card-item {
        display: flex;

        .catalog-card-item-photo {
            order: 2;
        }
    }

    .catalog-card-item:after {
        background: linear-gradient(90deg, #F5F6F6 46.88%, rgba(245, 246, 246, 0) 100%);
    
    }

    .catalog-card-item .catalog-card-item-photo {
        height: 220px;
        width: 48.55%;
        align-self: flex-end;
        position: relative;
        position: absolute;
        bottom: 0;
        right: 0;

        // &:after {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     background: linear-gradient(180deg, #F5F6F6 46.88%, rgba(245, 246, 246, 0) 100%);
        //     transform: rotate(-90deg);
        // }
    }

    .catalog-card-item {
        min-height: 220px;
    }

    .catalog-card-item .catalog-card-item-body {
        margin-top: 0;
        padding: 30px 0 40px 40px;
        width: calc(100% + 54px);
    }

    .catalog-card-item .cat-link {
        justify-content: flex-start;
        
    }
}

@media (max-width: $mx) {
    .catalog-page {
        padding-top: 28px;
        padding-bottom: 60px;
    }

    .catalog-page .page-title {
        margin-bottom: 30px;
    }

    .catalog-grid {
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
    }

    .catalog-card-item .catalog-card-item-photo {
        height: 212px;
        // max-width: 320px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .catalog-card-item .catalog-card-item-body {
        margin-top: -10px;
    }

    .catalog-card-item .catalog-card-item-body {
        padding: 0 20px 36px 20px;
    }

    .catalog-card-item .cat-link span {
        font-size: 18px;
        line-height: 24px;
        margin-right: 10px;
    }

    .catalog-card-item .catalog-card-item-col .cat-level-2 {
        font-size: 16px;
        line-height: 22px;
    }

    .callback-section--mg {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .personal-selection .form-inputs {
        margin-bottom: 30px;
    }

    .catalog-card-item .catalog-card-item-col ul li:not(:last-child) .cat-level-2 {
        margin-bottom: 10px;
    }

    

    .catalog-card-item .catalog-card-item-cols--no-mb-mob .catalog-card-item-col:not(:last-child) {
        margin-bottom: 10px;
    }


    .catalog-card-item .catalog-card-item-photo--fw-mb img {
        object-fit: cover;
    }

    .catalog-card-item {
        min-height: 280px;
    }
}