.features {
    padding-top: 80px;
    padding-bottom: 80px;
    background: #F5F6F6;

    .title {
        margin-bottom: 50px;
    }
}


.feature-card  {
    padding: 40px 30px;
    background-color: #fff;

    .ic {
        width: 52px;
        height: 52px;
        margin-bottom: 30px;
    }

    strong  {
        display: grid;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #1F1F1F;
        margin-bottom: 15px;
    }

    p {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #1F1F1F;
    }
}


.features-list {
    display: grid !important;
}

@media (max-width: $tb) {
    .features {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .features .title {
        margin-bottom: 40px;
    }

    body .features-list.cards-grid {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
    }

    .feature-card {
        padding: 30px 40px;
    }

    .feature-card strong {
        margin-bottom: 13px;
    }

    .feature-card p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media (min-width: $mb) {
    body .features-list.cards-grid {
        width: 100% !important;
    }

//     .feature-card {
//         width: 100% !important;
//     }
 }

@media (max-width: $mb) {
    body .features-list.cards-grid, .cards-grid {
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    }

    .text-content-2-cols {
        column-count: 1;
    }
    
}


@media (max-width: $mx) {
    .features {
        padding-top: 40px;
        padding-bottom: 42px;
    }


    .features-list {
        display: flex !important;
        grid-column-gap: 0;
        grid-row-gap: 0;
        grid-template-columns: none !important;
    }

    .features-slider {
        // height: 458px;
        height: 100%;
        margin-top: -10px;
    }

    body .features-slider {
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .features-slider.swiper-grid-column>.swiper-wrapper {
        flex-direction: row !important;
    }

    // .features-slider .swiper-slide {
    //     height: auto;
    // }

    body .features-list.cards-grid, .cards-grid {
        grid-column-gap: 0;
        grid-row-gap: 0;
    }

    .feature-card.swiper-slide   {
        // height: calc((100% ) / 2);
        margin-top: 10px !important;
        margin-bottom: 0 !important;
        height: auto;
    }


    .feature-card {
        padding: 30px;
    }

    .features .title {
        margin-bottom: 20px;
    }

    .feature-card .ic {
        margin-bottom: 18px;
    }

    .feature-card strong {
        margin-bottom: 9px;
    }

    body .features-slider {
        padding-bottom: 40px ;

        .swiper-pagination {
            bottom: 0 !important;
            left: 50%;
            transform: translateX(-50%) !important;
        }
    }
}