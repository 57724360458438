.files-col {
    padding-top: 40px;
    padding-bottom: 40px;
}

.files-col ul li:not(:last-child) {
    margin-bottom: 20px;
}

.file-link {
    display: flex;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #1F1F1F;

    .ic {
        width: 20px;
        height: 20px;
        min-width: 20px;
        margin-right: 12px;
    }
    
}

@media (max-width: $tb) {
    .files-col {
        padding-top: 30px;
        padding-bottom: 40px;
    }
}

@media (max-width: $mb) {
    .files-col {
        padding-top: 31px;
        padding-bottom: 10px;
    }
}