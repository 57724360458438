.callback-section  {
    padding-top: 80px;
    background-image: url('../img/callback-bg.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .wrapper {
        display: grid;
        grid-template-columns: 410px 1fr;
        grid-column-gap: 140px;
    }
}


.callback-section-title-col  {
    padding-bottom: 80px;

    .title {
        font-weight: bold;
        font-size: 32px;
        line-height: 44px;
        color: #FFFFFF;
        margin-bottom: 30px;
    }

    p {
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        color: #FFFFFF;

        & + p {
            margin-top: 15px;
        }
    }

    .box {
        margin-top: 30px;

        strong {
            display: block;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            color: #FFFFFF;
            margin-bottom: 20px;
        }

        .tel span {
            font-size: 24px;
            line-height: 32px;
        }
    }
}


.callback-form {
    display: flex;
    flex-direction: column;

    .submit-btn {
        margin-left: auto;
    }
}

.btn-in-bottom {
    margin-top: auto;
}

.callback-section--t2 .wrapper {
    display: grid;
    grid-template-columns: 1fr 410px;
    grid-column-gap: 100px;

    .form-inputs {
        margin-bottom: 40px;
    }
}


@media (max-width: $tb) {
    .callback-section {
        padding-top: 60px;
    }

    .callback-section--t2 .wrapper {
        grid-template-columns: 1fr 281px;
    }

    .callback-section-title-col .title {
        font-size: 28px;
        line-height: 40px;
    }

    .callback-section-title-col p {
        font-size: 16px;
        line-height: 24px;
    }

    .callback-section--t2 .callback-section-title-col .title {
        max-width: 437px;
    }

    .callback-section--t2 .callback-section-title-col p {
        max-width: 275px;
    }

    .callback-section:not(.callback-section--t2) .wrapper {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;

        .callback-section-title-col p {

            max-width: 316px;
        }

        .callback-section-title-col .box strong {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 11px;
        }
    }


    .callback-section-title-col .box .tel span {
        font-size: 20px;
        line-height: 28px;
    }

    .form-inputs {
        margin-bottom: 40px;
    }

    .callback-section--t3  .wrapper {
        grid-template-columns: 1fr 440px;

        .inputs-row + .inputs-row {
            margin-top: 30px;
        }
    }
}

@media (max-width: $mb) {
    .callback-section--t2 .wrapper {
        grid-template-columns: 1fr 245px;
    }

    .callback-section:not(.callback-section--t2) .wrapper {
        grid-template-columns: 100%;
        grid-row-gap: 50px;
    }

    .callback-section:not(.callback-section--t2) .wrapper .callback-section-title-col p {
        max-width: 100%;
    }

    .callback-section-title-col {
        padding-bottom: 0px;
    }
}


@media (max-width: $mx) {
    .callback-section--t2 {
        padding-top: 40px;
    }

    .callback-section--t2 .wrapper {
        grid-template-columns: 100%;
        grid-row-gap: 40px;
    }

    .callback-section-title-col .title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 20px;
    }

    .callback-section--t2 .callback-section-title-col p {
        font-size: 14px;
        line-height: 20px;
    }

    .callback-section--t2 .callback-section-title-col p {
        max-width: 240px;
    }

    .callback-section--t2 .wrapper .form-inputs {
        margin-bottom: 28px;
    }

    .callback-section {
        padding-top: 40px;
    }

    .callback-section:not(.callback-section--t2) .wrapper .callback-section-title-col p {
        font-size: 14px;
        line-height: 20px;
    }

    .callback-section:not(.callback-section--t2) .wrapper .callback-section-title-col .box strong {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 10px;
    }

    .callback-section-title-col .box {
        margin-top: 20px;
    }

    .callback-section:not(.callback-section--t2) .wrapper {
        grid-row-gap: 36px;
    }

    .form-inputs {
        margin-bottom: 30px;
    }
}