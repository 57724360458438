// @font-face {
//   font-family: 'Golos Text';
//   src: local('Golos Text Regular'), local('../fonts/GolosText-Regular'), url('../fonts/Golostextregular.woff2') format('woff2'), url('../fonts/Golostextregular.woff') format('woff'), url('../fonts/Golostextregular.ttf') format('truetype');
//   font-weight: 400;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'Golos Text';
//   src: local('Golos Text DemiBold'), local('../fonts/GolosText-DemiBold'), url('../fonts/Golostextdemibold.woff2') format('woff2'), url('../fonts/Golostextdemibold.woff') format('woff'), url('../fonts/Golostextdemibold.ttf') format('truetype');
//   font-weight: 600;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'Golos Text';
//   src: local('Golos Text Black'), local('../fonts/GolosText-Black'), url('../fonts/Golostextblack.woff2') format('woff2'), url('../fonts/Golostextblack.woff') format('woff'), url('../fonts/Golostextblack.ttf') format('truetype');
//   font-weight: 900;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'Golos Text';
//   src: local('Golos Text Medium'), local('GolosText-Medium'), url('../fonts/Golostextmedium.woff2') format('woff2'), url('../fonts/Golostextmedium.woff') format('woff'), url('../fonts/Golostextmedium.ttf') format('truetype');
//   font-weight: 500;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'Golos Text';
//   src: local('Golos Text Bold'), local('../fonts/GolosText-Bold'), url('../fonts/Golostextbold.woff2') format('woff2'), url('../fonts/Golostextbold.woff') format('woff'), url('../fonts/Golostextbold.ttf') format('truetype');
//   font-weight: 700;
//   font-style: normal;
// }


@font-face {
	font-family: 'Golos Text';
	src:
		url('../fonts/Golos-Text_Black.woff2') format('woff2'),
		url('../fonts/Golos-Text_Black.woff') format('woff'), url("../fonts/Golos-Text_Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
	font-family: 'Golos Text';
	src:
		url('../fonts/Golos-Text_Bold.woff2') format('woff2'),
		url('../fonts/Golos-Text_Bold.woff') format('woff'), url("../fonts/Golos-Text_Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
	font-family: 'Golos Text';
	src:
		url('../fonts/Golos-Text_DemiBold.woff2') format('woff2'),
		url('../fonts/Golos-Text_DemiBold.woff') format('woff'), url("../fonts/Golos-Text_DemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
	font-family: 'Golos Text';
	src:
		url('../fonts/Golos-Text_Medium.woff2') format('woff2'),
		url('../fonts/Golos-Text_Medium.woff') format('woff'), url("../fonts/Golos-Text_Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
	font-family: 'Golos Text';
	src:
		url('../fonts/Golos-Text_Regular.woff2') format('woff2'),
		url('../fonts/Golos-Text_Regular.woff') format('woff'), url("../fonts/Golos-Text_Medium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}




input {outline: none;}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


* {
  box-sizing: border-box;
  outline: none;
}

.nav-btn span {
  display: block;
}

button, a {
  cursor: pointer;
  transition: all .3s ease;
  border: none;
  background: none;
  text-decoration: none;
}

button {
  font-family: $defaultFont;
}

textarea {
  resize: vertical;
  font-family: $defaultFont;
}

input, textarea {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1D1D1F;

  &::placeholder {
    font-family: $defaultFont;
    opacity: 1;
  }
  
}

body {
  font-family: $defaultFont;
  font-size: 14px;  
  line-height: 20px;
  color: #1D1D1F;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.wrapper {
  max-width: 1320px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  &--xs {
    max-width: 990px;
  }

  &.ui-wrapper {
    max-width: 100%;
  }

 }

main {
  flex: 1;
  // overflow-x: hidden;
}

img {
  display: block;
    max-width: 100%;
}

.ic-btn {
  padding: 17px;
  background: rgba(230, 42, 42, 0.1);
  border-radius: 30px;

  &--accent {
    background-color: $accent;
  }
}



.accent-btn {
  display: block;
  width: fit-content;
  padding: 16px 40px;
  background-color: $accent;
  border-radius: 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;

  &--pale {
    background: rgba(230, 42, 42, 0.1);
    color: $accent;
    border: 1px solid transparent;

    
  }
}

.tel {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #1F1F1F;

  img {
    margin-right: 15px;
  }

  &--white {
    color: #fff;
  }
}

.rounded-ic {
  width: 54px;
  min-width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F5F6F6;
  border-radius: 27px;
}


// .map canvas {
// 	filter: grayscale(1) !important;
// 	-ms-filter: grayscale(1) !important;
// 	-webkit-filter: grayscale(1) !important;
// 	-moz-filter: grayscale(1) !important;
// 	-o-filter: grayscale(1) !important;
// }

ymaps[class*="ground-pane"] > ymaps > * {
  filter: grayscale(1) !important;
	-ms-filter: grayscale(1) !important;
	-webkit-filter: grayscale(1) !important;
	-moz-filter: grayscale(1) !important;
	-o-filter: grayscale(1) !important;
}




.raunded-btn {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 16px 40px;
  background-color: $accent;
  border-radius: 30px 30px 0px 0px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
  }

  svg {
    margin-left: 10px;
  }
}

.input-wrap {
  position: relative;

  input, textarea {
    display: block;
    width: 100%;
    padding: 24px 0 10px 0;
    border: none;
    border-bottom:  2px solid #BABABA;
    background: none;
    font-size: 'Golos Text', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
  }

  input {
    height: 54px;
  }

  textarea {
    min-height: 120px;
    resize: vertical;
  }

  label {
    position: absolute;
    top: 22px;
    left: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #BABABA;
    transition: all .3s ease;
    cursor: text;
  }

  &--white label {
    color: #fff;
  }

  &--white input {
    border-color: #fff;
  }
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(31, 31, 31, 0.4);
  backdrop-filter: blur(3px);
  z-index: 98;
  opacity: 0;
  visibility: hidden;
 

  &.active {
    display: block;
    opacity: 1;
    visibility: visible;
    transition: all .5s ease;
  }
}


.no-scroll {
  overflow: hidden;
}

.input-wrap.active label {
  font-size: 12px;
  line-height: 16px;
  color: #BABABA;
  top: 0;
}


.inputs-row {
  display: grid;

  &--t2 {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
  }


  & + .inputs-row {
    margin-top: 40px;
  }
}

.form-inputs {
  margin-bottom: 46px;
}


.more-btn {
  display: flex;
  align-items: center;
  width: fit-content;

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $accent;
  }

  .ic {
    width: 54px;
    height: 54px;
    min-width: 54px;
    background-color: $accent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    border-radius: 50%;
  }
}


sup {
  font-size: 85%;
  vertical-align: super;
  line-height: 96%;
}


.nav-buttons  {
  display: flex;
  width: fit-content;
}

.nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $accent;
  padding: 17px;
  width: fit-content;

  &.prev {
    border-radius: 0px 30px 0px 0px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }


  &.next {
    border-radius: 0px 30px 0px 0px;
  }
}

h1 {
  font-weight: bold;
  font-size: 44px;
  line-height: 54px;
  color: #1F1F1F;
}


h2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  color: #1F1F1F;
}

h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
}


.cards-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 40px;
}

.sidemarginauto {
  margin-left: auto;
  margin-right: auto;
}


.text-content p {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1F1F1F;

  & + p {
    margin-top: 21px;
  }
}

.text-content ul {
  margin-top: 40px;
  margin-bottom: 40px;

  li:not(:last-child) {
    margin-bottom: 10px;
  }

  li {
    position: relative;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
    padding-left: 28px;

    &::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $accent;
    }
  }


  
}

.text-content .img-wrap {
  width: 100%;
  margin-top: 60px;
}


.dk-bg {
  background: #F5F6F6;
}


.socials-row--vertical li:not(:last-child) {
  margin-bottom: 20px;
}


.accent-btn:hover, .nav-button:hover, .raunded-btn:hover, .sld-nav-btn:hover, .pagination .ic-btn:hover {
  opacity: 1;
  background-color: $accentHover;
}

.accent-btn--pale:hover{
  background: no-repeat;
  border-color: $accent;
}


.btn-in-center {
  margin-left: auto;
  margin-right: auto;
}


.more-btn:hover {
  opacity: 1;

  span {
    text-decoration-line: underline;
  }

  .ic {
    background-color: $accentHover;
  }
}

.cat-link:hover .ic {
  background-color: $accentHover;
}



.accent-btn:disabled, .raunded-btn:disabled {
  color: #1F1F1F;
  background: none;
  border: 1px solid #BABABA;
}


.has-sidebar {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-column-gap: 30px;
}



.check-block {
  display: flex;
}

.check-block .checkbox {
  width: 18px;
  min-width: 18px;
  height: 18px;
  border-radius: 4px;
  position: relative;
  margin-right: 12px;
  margin-top: 2px;
  padding: 0;
}

.check-block .checkbox input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.check-block .checkbox .checkbox-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  -webkit-transition: background-color .3s ease;
  -o-transition: background-color .3s ease;
  transition: background-color .3s ease;
  border-radius: 4px;
  border: 2px solid rgba(31, 31, 31, .4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.check-block .checkbox .checkbox-mask img {
  opacity: 0;
  -webkit-transition: opacity .3s ease;
  -o-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

.check-block .checkbox input:checked ~ .checkbox-mask {
  background: $accent;
  border-color: $accent;
}

.check-block .checkbox input:checked ~ .checkbox-mask img {
  opacity: 1;
}


.check-block label {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #0A2540;
  cursor: pointer;

  a {
    color: $accent;
  }
}


@media (max-width: $tb) {
  .wrapper {
    padding-left: 60px;
    padding-right: 60px;
  }

  h1 {
    font-size: 36px;
    line-height: 48px;
  }

  h2 {
    font-size: 28px;
    line-height: 40px;
  }

  
}


@media (max-width: 880px) {
  .wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }
}


@media (max-width: $mx) {
  .wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .ic-btn {
    padding: 0;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-size: 24px;
    line-height: 32px;
  }

  h2 {
    font-size: 20px;
    line-height: 28px;
  }


  .raunded-btn {
    padding: 14px 30px;
    
    span {
      font-size: 16px;
      line-height: 22px;
    }
  }


  .inputs-row + .inputs-row {
    margin-top: 30px;
  }

  .inputs-row--t2 {
    grid-template-columns: 100%;
    grid-row-gap: 30px;
  }

}



