.product-card {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-column-gap: 30px;
    padding-top: 30px;
    padding-bottom: 29px;
}

.product-card-photo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.product-card-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;

    .product-title {
        margin-bottom: 10px;
    }

    .product-cats {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #1F1F1F;
        margin-bottom: 30px;
    }

    .product-card-body-footer  {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
    }

    .characteristics {
        margin-bottom: 30px;
    }
}

.characteristics li:not(:last-child) {
    margin-bottom: 10px;
}


.characteristics li {
    display: flex;
    flex-wrap: wrap;

    span {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #AEAEAE;
        margin-right: 10px;
    }

    strong {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #1F1F1F;
    }
}

.characteristics--smaller li:not(:last-child) {
    margin-bottom: 10px;
}

.characteristics--smaller li span {
    font-size: 14px;
    line-height: 20px;
    margin-right: 8px;
}

.characteristics--smaller li strong {
    font-size: 14px;
    line-height: 20px;
}

.product-card-title-mob {
    display: none;
}

@media (max-width: $tb) {

    .product-card {
        grid-template-columns: 204px 1fr;
        grid-column-gap: 20px;

        .product-card-photo {
            height: 204px;
        }
    }

    .product-card-body .product-title {
        font-size: 20px;
        line-height: 28px;
    }

    .product-card-body .product-cats {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
    }

    .characteristics li span {
        font-size: 14px;
        line-height: 20px;
    }

    .characteristics li strong {
        font-size: 14px;
        line-height: 20px;
    }

    .characteristics li:not(:last-child) {
        margin-bottom: 6px;
    }

    .product-card-body .characteristics {
        margin-bottom: 20px;
    }
}


@media (max-width: $mb) {

    .product-card {
        grid-template-columns: 100%;
        grid-row-gap: 10px;
    }

    .product-card .product-card-photo {
        height: 120px;
    }

    .product-card-photo-container {
        display: grid;
        grid-template-columns: 120px 1fr;
        grid-column-gap: 12px;
    }

    .product-card-title-mob {
        display: block;

        .product-title {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 10px;
        }

        .product-cats {
            font-size: 14px;
            line-height: 18px;
        }
    }

    .product-card-body .product-title, .product-card-body .product-cats {
        display: none;
    }

    .product-card-body-footer .accent-btn {
        display: none;
    }

    .product-card-body .product-card-body-footer {
        justify-content: flex-end;
    }

    .characteristics li span {
        font-size: 12px;
        line-height: 16px;
        margin-right: 6px;
        margin-top: 6px;
    }

    .characteristics li strong {
        font-size: 12px;
        line-height: 16px;
        margin-top: 6px;
    }

    .characteristics li:not(:last-child) {
        margin-bottom: 0;
    }

    .product-card {
        grid-row-gap: 5px;

        .more-btn .ic {
            width: 48px;
            min-width: 48px;
            height: 48px;
        }
    }

    .product-card-body .characteristics {
        margin-bottom: 9px;
    }

    .product-card {
        padding-top: 20px;
        padding-bottom: 19px;
    }
}