.ui-content {
    padding: 40px 0;
    display: grid;
    grid-template-columns: 256px 1fr;
    gap: 24px 24px;
}

#ui-menu {
    position: sticky;
    top: 24px;

    ul {
        li:not(:last-child) {
            margin-bottom: 20px;
        }

        a {
            display: block;
            white-space: nowrap;
        }
    }
}

.ui-sidebar {
    padding-top: 40px;
}

.ui-box {
    padding: 40px 0;
    border-bottom: 1px solid #333;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px 24px;
}

.ui-box-title {
    font-size: 24px;
    line-height: 120%;
    font-weight: bold;
    margin-bottom: 32px;
}