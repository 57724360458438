.brands-production-page   .page-title {
    margin-bottom: 60px;
}

.brand-production-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
} 


.brand-product-card  {
    padding: 30px;
    background: #F5F6F6;
}

.brand-product-card-head {
    display: grid;
    grid-template-columns: 180px 1fr;
    grid-column-gap: 30px;
}

.brand-product-photo img {
    mix-blend-mode: darken;
}

.brand-product-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.brand-product-text strong {
    display: block;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #1F1F1F;
    margin-bottom: 10px;
}

.brand-product-text .cats {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #1F1F1F;
}

.brand-product-card-body  {
    padding-top: 20px;
}

.brand-product-card-footer {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.brand-product-photo--tb  {
    display: none;
}


@media (min-width: $mx) and (max-width: $tb) {
    

    .brand-product-photo--tb  {
        display: block;
        height: 180px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            mix-blend-mode: darken;
        }
    }

    .brand-product-card-head {
        grid-template-columns: 100%;
    }

    .brand-product-card-head .brand-product-photo {
        display: none;
    }

    .brand-product-card-content {
        display: grid;
        grid-template-columns: 180px 1fr;
        grid-column-gap: 30px;
    }
}

@media (max-width: $tb) {
    
    .brand-production-list {
        grid-template-columns: 100%;
    }

    .brands-production-page .page-title {
        margin-bottom: 50px;
    }

    .brand-product-text strong {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
    }

    .brand-product-text .cats {
        font-size: 16px;
        line-height: 22px;
    }
    
}


@media (max-width: $mb) {
    .brand-production-list {
        width: calc(100% + 80px);
        margin-left: -40px;
        margin-right: -40px;
        grid-row-gap: 10px;
    }

    .brand-product-card {
        padding: 30px 40px;
    }

    .brand-product-card .characteristics li span, .brand-product-card .characteristics li strong {
        font-size: 14px;
        line-height: 20px;
    }

    .brand-product-card-body {
        padding-top: 14px;
    }

    .brands-production-page {
        padding-bottom: 80px;
    }
}


@media (max-width: $mx) {
    .brand-production-list {
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
    }

    .brand-product-card-footer {
        justify-content: flex-end;
    }

    .brand-product-card-footer .accent-btn {
        display: none;
    }

    .brand-product-card-head {
        grid-template-columns: 120px 1fr;
        grid-column-gap: 12px;
    }

    .brand-product-card {
        padding: 20px;
    }

    .brands-production-page .page-title {
        margin-bottom: 30px;
    }

    .brand-product-text strong {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
    }

    .brand-product-text .cats {
        font-size: 14px;
        line-height: 18px;
    }

    .brand-product-text {
        justify-content: flex-start;
    }

    .brand-product-photo {
        margin-top: 4px;
    }

    .brand-product-card .characteristics li span, .brand-product-card .characteristics li strong {
        font-size: 12px;
        line-height: 16px;
    }

    .brand-product-card .more-btn .ic {
        width: 48px;
        min-width: 48px;
        height: 48px;
    }

    .brand-product-card-footer {
        margin-top: 9px;
    }

    .brands-production-page {
        padding-bottom: 60px;
    } 

}