.filter-block-title {
    display: block;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #1F1F1F;

    & + * {
        margin-top: 20px;
    }
}

.checks-list li:not(:last-child) {
    margin-bottom: 10px;
}

.checks-block--accent {
    padding: 20px;
    background: #F5F6F6;
}

.filter-box + .filter-box {
    margin-top: 40px;
}


.filter-box ul:not(.checks-list) li:not(:last-child) {
    margin-bottom: 20px;
}

.filter-box ul:not(.checks-list) li a {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
}

.filter-box ul.level-2 {
    padding-left: 20px;
    margin-top: 15px;
    display: none;

    li:not(:last-child) {
        margin-bottom: 10px;
    }

    li a {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #1F1F1F;

        &:hover {
            color: $accent;
        }
    }
}

li.has-submenu {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #F5F6F6;
        background-image: url('../img/svg/icon-plus.svg');
        background-position: center center;
        background-repeat: no-repeat;
    }

    &.active:after {
        background-image: url('../img/svg/icon-minus.svg');
    }
}

.filter {
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}



@media (max-width: $tb) { 

    .filter {
        padding-bottom: 35px;
    }

    .filter-box + .filter-box {
        margin-top: 30px;
    }

    .filter-block-title {
        font-size: 16px;
        line-height: 22px;
    }

    .filter-block-title + * {
        margin-top: 15px;
    }

    .filter-box ul.level-2 {
        padding-right: 20px;
    }

    .filter-box ul.level-2 li a {

        word-break: break-word;
    }

    .filter-box ul:not(.checks-list) li:not(:last-child) {
        margin-bottom: 15px;
    }


    .checks-block  .filter-block-title + * {
        margin-top: 20px;
    }

    .filter-box ul.level-2 li:not(:last-child) {
        margin-bottom: 10px;
    }

    

    
}


@media (max-width: $mb) {
    .filter {
        padding-bottom: 28px;
    }
}