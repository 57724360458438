.main-footer {
    background: #F5F6F6;
}


.contact-column {
    padding: 80px 40px 60px 40px;
    max-width: 520px;
    width: 100%;
    background: #FFFFFF;
    position: relative;
    z-index: 2;
}

.contact-column-head  {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
}

.socials {
    display: flex;

    li:not(:last-child) {
        margin-right: 20px;
    }
}


.contacts-list-item {
    display: flex;

    .ic {
        margin-right: 30px;
        margin-top: 14px;
    }
}

.contacts-list .contacts-list-item:not(:last-child) {
    margin-bottom: 30px;
}

.contacts-list-item-text {
    flex: 1;

    span {
        display: block;
        font-size: 14px;
        line-height: 18px;
        color: #BABABA;
        margin-bottom: 10px;
    }

    p, a {
        display: block;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #1F1F1F;
    }
}

.main-footer .wrapper {
    display: flex;
}

.footer-menus-col {
    flex: 1;
    padding: 80px 0 251px 80px;
    display: flex;
    flex-direction: column;

}

.footer-menus {
    display: flex;
    margin-bottom: 40px;

    .footer-menu:not(:last-child) {
        margin-right: 30px;
    }
}

.footer-menu {
    max-width: 330px;
    width: 100%;

    .lbl {
        display: block;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        color: #1F1F1F;
        margin-bottom: 30px;
    }

    ul li:not(:last-child) {
        margin-bottom: 10px;
    }

    ul li a {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #1F1F1F;
    }
}

.copy {
    margin-top: auto;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #BABABA;
}

.map {
    height: 400px;
    position: relative;
    margin-top: -171px;
    z-index: 1;
}


@media (max-width: $tb) {
    .main-footer .wrapper {
        padding-left: 0;
    }

    .contact-column {
        padding: 60px 30px 40px 60px;
        max-width: 497px;
    }

    .socials li img {
        width: 20px;
    }

    .contact-column-head {
        margin-bottom: 40px;
    }

    .contacts-list-item-text span {
        font-size: 12px;
        line-height: 16px;
        color: #BABABA;
    }

    .contacts-list-item-text p, .contacts-list-item-text a {
        font-size: 14px;
        line-height: 20px;
    }

    .contacts-list-item .ic {
        margin-right: 20px;
        margin-top: 9px;
    }

    .contacts-list .contacts-list-item:not(:last-child) {
        margin-bottom: 20px;
    }


    .footer-menus-col {
        padding: 60px 0 138px 29px
    }

    .footer-menu .lbl {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
    }

    .footer-menu ul li a {
        font-size: 14px;
        line-height: 20px;
    }

    .footer-menus {
        margin-bottom: 30px;
    }

    .footer-menu {
        max-width: 201px;
    }

    .map {
        margin-top: -110px;
    }

    .copy {
        font-size: 12px;
        line-height: 18px;
    }
}



@media (max-width: $mb) {
    .main-footer .wrapper {
        flex-direction: column;
        width: 100%;
    }

    .contact-column {
        max-width: calc(100% + 80px);
        padding: 60px 40px 40px 40px;
        width: calc(100% + 80px);
        margin-left: -40px;
        margin-right: -40px;
    }

    .main-footer .wrapper {
        padding-left: 40px;
    }

    .contacts-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    }

    .contacts-list .contacts-list-item:not(:last-child) {
        margin-bottom: 0;
    }

    .footer-menus-col {
        padding: 40px 0 40px 0;
    }

    .footer-menu {
        max-width: 334px;
    }


    .footer-menus .footer-menu:not(:last-child) {
        margin-right: 20px;
    }

    .footer-menu .lbl {
        margin-bottom: 18px;
    }

    .map {
        margin-top: 0;
    }
}


@media (max-width: $mx) {
    .main-footer .wrapper {
        padding-left: 20px;
    }


    .contact-column {
        max-width: calc(100% + 40px);
        padding: 37px 20px 30px 20px;
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
    }

    .contacts-list {
        grid-template-columns: 100%;
        grid-column-gap: 20px;
        grid-row-gap: 15px;
    }

    .contact-column-head {
        margin-bottom: 32px;

        .socials {
            margin-top: 8px;
        }
    }

    .contacts-list-item .ic {
        width: 48px;
        min-width: 48px;
        height: 48px;
        margin-right: 16px;
    }

    .contacts-list-item-text span {
        margin-bottom: 6px;
    }

    .footer-menus {
        flex-direction: column;
    }

    .footer-menus .footer-menu:not(:last-child) {
        margin-right: 0;
        margin-bottom: 30px;
    }

    .footer-menus-col {
        padding: 30px 0 30px 0;
    }

    .footer-menu .lbl {
        margin-bottom: 14px;
    }

    .footer-menu ul li:not(:last-child) {
        margin-bottom: 8px;
    }
}