.catalog-list-page .page-title {
    margin-bottom: 60px;
}

.product-list li:first-child .product-card  {
    padding-top: 0;
}

.product-list li + li .product-card {
    border-top: 1px solid rgba(0, 0, 0, .1);
}


.catalog-list-page .pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 30px;
}


@media (max-width: 1160px) {

    .has-sidebar {
        grid-template-columns: 203px 1fr;
    }
    

}


@media (max-width: $tb) {
    .catalog-list-page .page-title {
        margin-bottom: 50px;
    }

    .catalog-list-page .pagination {
        margin-top: 10px;
    }

}


@media (max-width: 940px) {
    .has-sidebar {
        display: flex;
        flex-direction: column;
    

        .aside {
            order: 2;
            margin-top: 60px;
        }
    }
}

@media (max-width: $mb) {
    .filter-block-title + * {
        margin-top: 10px;
    }

    .filter-box ul.level-2 {
        margin-top: 10px;
    }

    .filter-box ul:not(.checks-list) li:not(:last-child) {
        margin-bottom: 10px;
    }

    .filter-box + .filter-box {
        margin-top: 25px;
    }

    .checks-block--accent {
        margin-bottom: 30px;
    }

    .filter {
        padding-bottom: 28px;
    }

    .catalog-list-page .page-title {
        margin-bottom: 30px;
    }

    .catalog-list-page .pagination {
        margin-top: 20px;
    }

    .has-sidebar .aside {
        margin-top: 40px;
    }
}