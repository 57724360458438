.page-with-bradcrumbs {
    padding-top: 60px;
    padding-bottom: 100px;
}

.bredcrumbs {
    margin-bottom: 40px;

    a {


        position: relative;


        .dot  {
            width: 4px;
            height: 4px;
            background-color: $accent;
            border-radius: 50%;
            display: inline-block;
            margin-left: 20px;
            margin-right: 20px;
            transform: translateY(-3px);
        }

    }

     a {
        // display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #1F1F1F;
    }

    .ellipsis {
        // display: inline-block;
        margin-right: 20px;
        transform: translateY(-3px);
        display: none;
        pointer-events: none;
        min-width: 22px;
    }

    &.collapsed .ellipsis {
        display: inline-block !important;
    }

    &.collapsed > *:not(:nth-last-child(-n+2)) {
        display: none;
    }
}

.news-detail-page .page-title  {
    margin-bottom: 60px;
}

.detail-grid {
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-column-gap: 60px;
}

.detail-grid-inner {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-column-gap: 60px;
}

.left-sidebar  {
    padding-right: 10px;

    .socials-row {
        position: sticky;
        top: 20px;
    }
}

.read-more-col {
    position: sticky;
    top: 20px;
}

.read-more-col .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #1F1F1F;
    margin-bottom: 20px;
}


.read-more-col ul li a {
    display: block;
    padding: 20px 0 19px 0;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: #1F1F1F;
    border-top: 1px solid rgba(31, 31, 31, .1);
}   


.date-box .date {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #1F1F1F;
    transform: rotate(-90deg);
    white-space: nowrap;
 
}

.date-box {
    min-height: 164px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    margin-bottom: 60px;
}

.artcl-content {
    width: 100%;

    & > img {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .main-thumbnail {
        margin-bottom: 40px;

        img {
            max-width: none;
            width: 100%;
        }
    }


    p {
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        color: #1F1F1F;

        & + p {
            margin-top: 20px;
        }

        a {
            color: $accent;
        }
    }

    h3 {
        margin-top: 60px;
        margin-bottom: 20px;
    }

    ul {
        margin-top: 20px;
        margin-bottom: 20px;
      
        li:not(:last-child) {
          margin-bottom: 21px;
        }
      
        li {
          position: relative;
          font-weight: normal;
          font-weight: 500;
            font-size: 18px;
            line-height: 24px;
          color: #1F1F1F;
          padding-left: 36px;
      
          &::before {
            content: "";
            position: absolute;
            top: 9px;
            left: 5px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $accent;
          }
        }
    }



    .gallery {
        margin-top: 40px;
        margin-bottom: 40px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
    }

    blockquote {
        display: block;
        position: relative;
        width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
        padding: 92px 60px 40px 60px;
        background: #FFFFFF;
        text-align: center;
      
        &:before {
          content: "";
          position: absolute;
          top: 40px;
          left: 50%;
          transform: translateX(-50%);
          width: 32px;
          height: 32px;
          background-image: url('../img/svg/quotes.svg');
          background-position: center center;
          background-repeat: no-repeat;
        }
        
        & > p {
         font-size: 18px;
         line-height: 24px;
         text-align: center;
         color: #1F1F1F;
         margin-bottom: 20px;
        }
      
        span {
          display: block;
         font-weight: bold;
         font-size: 18px;
         line-height: 24px;
         color: $accent;
        }
      }
}

.sidebar .art-socials-col {
    display: none;
}


@media (max-width: $tb) {

    .page-with-bradcrumbs {
        padding-top: 40px;
        padding-bottom: 80px;
    }

    .detail-grid {
        display: flex;
        flex-direction: column;
        grid-column-gap: 0;
    }

    .left-sidebar {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
        padding-right: 0;
    }

    .date-box {
        width: 164px;
        min-width: 164px;
        height: 40px;
        min-height: auto;
        margin-bottom: 0;

        .date {
            transform: none;
        }
    }

    .socials-row--vertical {
        display: flex;

        li:not(:last-child) {
            margin-bottom: 0;
            margin-right: 20px;
        }
    }

    .detail-grid-inner {
        grid-template-columns: 1fr 204px;
        grid-column-gap: 30px;
    }

    .news-detail-page .page-title {
        margin-bottom: 30px;
    }

    .bredcrumbs {
        margin-bottom: 30px;
    }

    .read-more-col .title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 13px;
    }

    .read-more-col ul li a {
        font-size: 16px;
        line-height: 24px;
        padding-top: 15px;
        padding-bottom: 14px;
    }

    .artcl-content p {
        font-size: 18px;
        line-height: 28px;
    }

    .artcl-content h3 {
        font-size: 20px;
        line-height: 28px;
        margin-top: 40px;
    }

    .artcl-content ul li {
        padding-left: 32px;
        font-size: 16px;
        line-height: 22px;
    }

    .artcl-content blockquote {
        padding: 84px 40px 40px 40px;
        margin-bottom: 30px;
    }

    .artcl-content blockquote:before {
        top: 32px;
    }

    .artcl-content blockquote span {
        font-size: 16px;
        line-height: 22px;
    }
}


@media (max-width: $mb) {
    .page-with-bradcrumbs {
        padding-bottom: 68px;
    }

    .left-sidebar .socials-row {
        margin-right: 3px;
    }

    .detail-grid-inner {
        display: flex;
        flex-direction: column;
        grid-column-gap: 0;
    }

    .artcl-content .gallery {
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    }

    .artcl-content blockquote {
        padding-bottom: 30px;
        margin-bottom: 40px;
    }

    .sidebar {
        margin-top: 60px;
    }

  
}


@media (max-width: $mx) {
    .left-sidebar .socials-row {
        display: none;
    }

    .page-with-bradcrumbs {
        padding-top: 18px;
        padding-bottom: 60px;
    }

    .bredcrumbs li {
        padding-right: 28px;
    }

    .bredcrumbs li:after {
        right: 12px;
    }

    .bredcrumbs li a {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.05em;
    }

    .bredcrumbs {
        margin-bottom: 20px;
    }

    .news-detail-page .page-title {
        margin-bottom: 20px;
    }

    .date-box {
        width: auto;
        min-width: 139px;
        height: 32px;
        margin-left: -20px;
        padding-left: 20px;
        padding-right: 12px;

        .date {
            font-size: 12px;        
            line-height: 16px;
        }
    }

    .left-sidebar {
        margin-bottom: 20px;
    }

    .artcl-content .main-thumbnail {
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;

        img {
            height: 240px;
        }
    }

    .artcl-content .main-thumbnail {
        margin-bottom: 20px;
    }

    .artcl-content p {
        font-size: 16px;
        line-height: 24px;
    }

    .artcl-content p + p {
        margin-top: 15px;
    }

    .artcl-content h3 {
        font-size: 18px;
        line-height: 24px;
        margin-top: 30px;
    }

    .artcl-content ul li {
        font-size: 14px;
        line-height: 20px;
    }

    .artcl-content ul li:not(:last-child) {
        margin-bottom: 15px;
    }

    .artcl-content .gallery {
        margin-top: 30px;
        margin-bottom: 30px;
        grid-template-columns: 100%;
        grid-row-gap: 20px;
    }


    .artcl-content blockquote {
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
        margin-top: 30px;
        margin-bottom: 30px;
        padding: 67px 20px 20px 20px;

        span {
            font-size: 16px;
            line-height: 22px;
        }

        & > p {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 15px;
        }

        &:before {
            top: 20px;
        }
    }


    .sidebar .art-socials-col {
        display: block;
        padding: 30px 20px;
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: 30px;
        border-top: 1px solid rgba(31, 31, 31, .1);
        border-bottom: 1px solid rgba(31, 31, 31, .1);

        .title {
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: #1F1F1F;
            margin-bottom: 20px;
        }

        .socials-row {
            display: flex;

            li:not(:last-child) {
                margin-right: 16px;
            }
        }
    }

    .sidebar {
        margin-top: 30px;
    }

    .read-more-col .title {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 15px;
    }

    .page-with-bradcrumbs {
        padding-bottom: 45px;
    }


    .bredcrumbs a {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.05em;
        margin-bottom: 12px;
    }

    .bredcrumbs a .dot {
        margin-left: 12px;
        margin-right: 12px;
    }

    .bredcrumbs .ellipsis {
        margin-right: 12px;
    }

}