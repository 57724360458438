// Fonts

$defaultFont: 'Golos Text', sans-serif;


//Colors
$accent: #E62A2A;
$accentHover: #CE0000;



$tb: '1024px';

$tb2: '1050px';

$mb: '768px';

$mx: '577px';