.logos-section {
    padding-top: 60px;
    padding-bottom: 60px;
}

.logos-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 5.4%;
    grid-row-gap: 30px;

    li img {
        width: 100%;
    }
}

@media (max-width: $tb) {
    .logos-grid {
        grid-column-gap: 4%;
    }
}


@media (max-width: $mb) {

    .logos-section {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .logos-grid {
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 60px;
        padding-left: 54px;
        padding-right: 54px;
    }
}


@media (max-width: $mx) { 
    .logos-section {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .logos-grid {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 80px;
        grid-row-gap: 30px;
        padding-left: 40px;
        padding-right: 40px;
    }
}