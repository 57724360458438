.about-us {
    background-image: url('../img/bank-phrom-Tzm3Oyu_6sk-unsplash.jpg');
    background-position: top 0 right 0;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 80px;
    

    .wrapper {
        display: flex;
        background-image: url('../img/svg/about-us-shape.svg');
        background-position: top 0 right 15px;
        background-repeat: no-repeat;
        padding-bottom: 102px;
    }

    .title {
        margin-bottom: 60px;
    }

    p {
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        color: #1F1F1F;

        & + p {
            margin-top: 20px;
        }
        
        strong {
            font-weight: 700;
        }
    }

    .p-xs {
        font-size: 16px;
        line-height: 24px;
    }
}

.about-us-content {
    max-width: 740px;
    width: 100%;
    margin-right: 30px;

    .more-btn {
        margin-top: 30px;
    }
}

.cnt-lst  {
    margin-top: 80px;
    margin-bottom: 80px;
    display: flex;

    li {
        max-width: 155px;
    }

    li:not(:last-child) {
        margin-right: 100px;
    }

    li strong {
        display: block;
        font-size: 64px;
        line-height: 48px;
        color: $accent;
        margin-bottom: 30px;
    }

    li span {
        display: block;
        font-size: 18px;
        line-height: 26px;
        color: #1F1F1F;
    }
}

.about-us-col  {
    padding-top: 172px;
}


@media (max-width: $tb) {
    .about-us {
        padding-top: 60px;

        .wrapper {
            padding-bottom: 60px;
        }
    }

    .about-us .title {
        margin-bottom: 40px;
    }

    .about-us-content {
        max-width: 593px;
    }

    .cnt-lst {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .cnt-lst li span {
        font-size: 16px;
        line-height: 24px;
    }

    .about-us-col .logo-big {
        width: 167px;
    }

    .about-us-col {
        padding-top: 190px;
        transform: translateX(-71px);
    }

    .about-us .wrapper {
        background-size: 437px;
        background-position: top 86px right 60px;
    }
}

@media (max-width: $mb) {
    .about-us-content {
        max-width: 511px;
        margin-right: 11px;
    }

    .about-us-col {
        padding-top: 41.5%;
        transform: none;
    }

    .about-us .wrapper {
        background-size: 511px;
        background-position: top 0 right 40px;
    }
}


@media (max-width: $mx) {
    .about-us {
        padding-top: 30px;

        .wrapper {
            padding-bottom: 30px;
        }
    }

    .about-us .wrapper {
        background-size: 320px;
        background-position: top 0 right -73px;
    }


    .about-us .title {
        margin-bottom: 30px;
    }

    .about-us p {
        font-size: 16px;
        line-height: 24px
    }

  
    .cnt-lst li:not(:last-child) {
        margin-right: 10px;
    }

    .cnt-lst li strong {
        font-size: 48px;
        line-height: 40px;
        margin-bottom: 20px;
    }

    .cnt-lst li span {
        font-size: 14px;
        line-height: 20px;
    }   

    .about-us-col {
        display: none;
    }

    .cnt-lst {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .about-us-content {
        margin-right: 0;

        .more-btn .ic {
            width: 48px;
            min-width: 48px;
            height: 48px;
        }
    }


    .about-us .p-xs {
        font-size: 14px;
        line-height: 20px;
    }
}