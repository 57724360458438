.pagination .prev svg {
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.pagination  {
    display: flex;
    align-items: center;
    margin-top: 60px;

    ul {
        display: flex;
        align-items: center;

        li:not(:last-child) {
            margin-right: 10px;
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 54px;
            min-width: 54px;
            height: 54px;
            border-radius: 50%;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: $accent;

            &.current {
                color: #1F1F1F;
                background: #F5F6F6;
            } 

            &:not(.current):hover {
                background: rgba(230, 42, 42, 0.1);
            }
        }
    }
}

.pagination .prev {
    margin-right: 10px;
    height: 54px;
    display: none;
}

.pagination .next {
    margin-left: 10px;
    height: 54px;
} 


@media (max-width: $tb) {
    .pagination {
        margin-top: 40px;
    }
}


@media (max-width: $mx) {
    .pagination {
        margin-top: 18px;
        justify-content: space-between;
    }

    .pagination .prev, .pagination .next {
        width: 48px;
        height: 48px;
        min-width: 48px;
    }

    .pagination ul li:not(:last-child) {
        margin-right: 0;
    }

    .pagination ul a {
        width: 48px;
        min-width: 48px;
        height: 48px;
    }
}