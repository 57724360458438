.get-consult {
    padding: 80px 58px 60px 60px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(15px);
    height: fit-content;

    .title {
        color: #FFFFFF;
        margin-bottom: 20px;
    }

    p {
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        
        & + p {
            margin-top: 20px;
        }
    }

    .inputs-row {
        margin-top: 30px;
    }

    .accent-btn {
        margin-top: 40px;
        width: 100%;
    }
}

@media (max-width: $tb) {
    .get-consult {
        padding: 60px 60px 40px 60px;
        max-width: 400px;
    }

    .get-consult .title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 20px;
    }

    .get-consult p {
        font-size: 14px;
        line-height: 20px;
    }

}

@media (max-width: $mb) {
    .get-consult {
        padding: 30px;
        max-width: 100%;
    }

    .get-consult {
        display: grid;
        grid-template-columns: 1fr 240px;
        grid-column-gap: 30px;

        .inputs-row {
            margin-top: 0;
        }
    
        .accent-btn {
            margin-top: 30px;
        }
    }

    
}


@media (max-width: $mx) {
    .get-consult {
        grid-template-columns: 100%;
        grid-row-gap: 30px;
        width: calc(100% + 40px);
        max-width: none;
        margin-left: -20px;
        margin-right: -20px;


        .accent-btn {
            padding: 13px 40px;
        }
    }
}