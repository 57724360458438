.news-section  {
    padding-top: 80px;
    background: #F5F6F6;

    .wrapper > .title {
        margin-bottom: 50px;
    }
}


.news-grid  {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
}
    
.news-grid li:nth-child(1) { grid-area: 1 / 1 / 3 / 3; }
.news-grid li:nth-child(2) { grid-area: 1 / 3 / 2 / 4; }
.news-grid li:nth-child(3) { grid-area: 2 / 3 / 3 / 4; } 


.news-tile {
    display: flex;
    flex-direction: column;
}

.news-tile .news-tile-photo  {
    height: 228px;
    position: relative;

    .lbl {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #1F1F1F;
        padding: 7px 10px 7px 20px;
        background: #F5F6F6;
    }

    .lbl {
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translateY(50%);
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.news-tile {
    background-color: #fff;
    height: 100%;
}

.news-tile .news-tile-body {
    padding: 30px 20px 20px 20px;

    .news-title {
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 24px;
    }

    .exerpt {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #1F1F1F;
        line-clamp: 2;
        -webkit-line-clamp: 2; 
        display: -webkit-box; 
        -webkit-box-orient: vertical; 
        overflow: hidden;
    }
}



.news-grid li:nth-child(1) .news-tile {
    .news-tile-photo  {
        height: 496px;

        .lbl {
            font-size: 14px;
            line-height: 20px;
            padding: 10px 20px 10px 30px;
        }
    }
    

    .news-tile-body  {
        padding: 50px 30px 30px 30px;
        

        .news-title {
            font-size: 24px;
            line-height: 32px;
        }


        .exerpt  {
            margin-bottom: 30px;
        }
    }
}





.news-section .btn-in-bottom {
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
}





@media (max-width: $tb) {
    .news-section {
        padding-top: 60px;
    }

    .news-section .wrapper > .title {
        margin-bottom: 40px;
    }

    .news-tile .news-tile-photo {
        height: 178px;
    }

    .news-tile .news-tile-body .news-title {
        font-size: 16px;
        line-height: 22px;
    }

    .news-tile .news-tile-body .exerpt {
        font-size: 14px;
        line-height: 20px;
        line-clamp: 3;
        -webkit-line-clamp: 3; 
    }

    .news-grid li:nth-child(1) .news-tile {
        .news-tile-body {
            padding-top: 40px;
        }

        .news-tile-photo {
            height: 474px;
        }

        .news-tile-body .news-title {
            font-size: 20px;
            line-height: 28px;
        }

        .news-tile-body .exerpt {
            font-size: 16px;
            line-height: 24px;
            line-clamp: 2;
            -webkit-line-clamp: 2; 
        }
    }

    .news-section .btn-in-bottom {
        margin-top: 60px;
    }
}

@media (max-width: $mb) {

    .news-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 0;
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-right: -10px;
        margin-top: -10px;
    }

    .news-grid li:nth-child(1) {
        width: 100%;
    }

    .news-grid li {
        width: 50%;
        padding: 10px;
    }

    .news-tile .news-tile-photo {
        height: 236px;
    }

    .news-grid li:nth-child(1) .news-tile {
        .news-tile-body {
            padding-top: 40px;
        }

        .news-tile-photo {
            height: 316px;
        }

        .news-tile-body .news-title {
            font-size: 20px;
            line-height: 28px;
        }

        .news-tile-body .exerpt {
            font-size: 16px;
            line-height: 24px;
            line-clamp: 2;
            -webkit-line-clamp: 2; 
        }
    }


    .news-section .btn-in-bottom {
        margin-top: 50px;
    }
}


@media (max-width: $mx) {
    .news-section {
        padding-top: 40px;
    }

    .news-section .wrapper > .title {
        margin-bottom: 20px;
    }

    .news-grid li:nth-child(1) .news-tile .news-tile-photo, .news-tile .news-tile-photo {
        height: 236px;
    }

    .news-grid li:nth-child(1) .news-tile .news-tile-body, .news-tile .news-tile-body {
        padding: 30px 20px 20px 20px;
    }

    .news-grid li:nth-child(1) .news-tile .news-tile-body .news-title, .news-tile .news-tile-body .news-title {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
    }

    .news-grid li:nth-child(1) .news-tile .news-tile-body .exerpt, .news-tile .news-tile-body .exerpt {
        font-size: 14px;
        line-height: 20px;
        line-clamp: 3;
        -webkit-line-clamp: 3;
        margin-bottom: 0;
    }

    .news-grid li:nth-child(1) .news-tile .news-tile-photo .lbl, .news-tile .news-tile-photo .lbl {
        padding: 7px 10px 7px 20px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.05em;
    }

    .news-grid li {
        width: 100%;
    }

    .news-grid li:nth-child(1) .news-tile .more-btn {
        display: none;
    }


    .news-section .btn-in-bottom {
        margin-top: 19px;
    }
}