.procedure  {
    padding-top: 80px;
    background: #F5F6F6;
    overflow-x: hidden;

    .wrapper > .title {
        margin-bottom: 50px;
    }

    .btn-in-bottom {
        margin-top: 80px;
    }
}

body .procedure-list {
    display: grid;
}

.procedure-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 60px;
    max-width: 1260px;
    
}

.procedure-item .num {
    font-weight: normal;
    font-size: 64px;
    line-height: 48px;
    color: $accent;
    margin-bottom: 40px;
}

.procedure-item strong {
    display: block;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #1F1F1F;
    margin-bottom: 15px;
}

.procedure-item p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
}


@media (max-width: $tb) { 
    .procedure {
        padding-top: 60px;
    }

    .procedure .wrapper > .title {
        margin-bottom: 40px;
    }

    .procedure-list {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 60px;
        grid-column-gap: 30px;
    }

    .procedure-item .num {
        font-size: 48px;
        line-height: 40px;
        margin-bottom: 20px;
    }

    .procedure-item strong {
        font-size: 16px;
        line-height: 22px;

        br {
            display: none;
        }
    }

    .procedure-item p {
        font-size: 14px;
        line-height: 20px;
    }

    .procedure .btn-in-bottom {
        margin-top: 60px;
    }
}

.procedure-slider .swiper-pagination {
    display: none ;
}

@media (max-width: $mx) {
    .procedure {
        padding-top: 40px;
    }

    .procedure .wrapper > .title {
        margin-bottom: 30px;
    }

    body .procedure-list {
        display: flex;
        grid-template-columns: 100%;
        grid-row-gap: 0;
        grid-column-gap: 0;
    }


    .procedure-slider {
        padding-bottom: 46px !important;
        width: calc(100% + 40px);
        

        .swiper-pagination {
            bottom: 0!important;
            left: 50%;
            transform: translateX(-50%);
            display: block;
        }
    }

    body .procedure-slider {
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .procedure .btn-in-bottom {
        margin-top: 26px;
    }
}