.main-header {
    background-color: #fff;
    box-shadow: 0px 6px 40px rgba(31, 31, 31, 0.05);
}

.header-main-row {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(31, 31, 31, .1);

    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.logo {
    display: flex;
    align-items: center;
}

.logo-descr  {
    margin-left: 40px;

    p {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #BABABA;

        & + p {
            margin-top: 4px;
        }
    }
}

.header-actions {
    display: flex;
  
    .action + .action {
      margin-left: 20px;
    }

    .action {
        height: 54px;
    }
}

.main-navigation {
    display: flex;
    // align-items: center;
    justify-content: space-between;

    .tel {
        padding-bottom: 30px;
        padding-top: 20px;
    }

    ul li a {
        padding-top: 20px;
        padding-bottom: 32px;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            border-radius: 12px 12px 0px 0px;
            background-color: transparent;
        }

        &.active {
            color: $accent;
            position: relative;

            &:before {
                background-color: $accent;
            }
        }

        &:hover {
            color: $accent;
            position: relative;

            &:before {
                background-color: $accent;
            }
        }
    }
}

.header-nav-row {
    // padding-top: 20px;
    // padding-bottom: 29px;

    ul {
        display: flex;

        li:not(:last-child) {
            margin-right: 20px;
        }


        a {
            display: block;
            padding: 0 10px;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #1F1F1F;
            
        }
    }
}

.header-mobal-actions {
    display: none;
}

.get-consult-btn {
    display: none;
}

.contacts-us-box  {
    display: none;
}

.menu-btn .act {
    display: none;
}


.menu-btn.active .act {
    display: block;
}

.menu-btn.active .def {
    display: none;
}


@media (max-width: $tb) {
    // .header-main-row .wrapper, .main-navigation {
    //     padding-left: 60px;
    //     padding-right: 60px;
    // }

    .logo-descr {
        margin-left: 30px;
    }

    .header-nav-row ul li:not(:last-child) {
        margin-right: 10.5px;
    }
}

@media (max-width: 880px) {
    .header-actions .action.accent-btn {
        padding-left: 20px;
        padding-right: 20px;
    }

    .logo-descr {
        display: none;
    }
}

@media (max-width: 800px) {
    .header-nav-row ul a {
        padding: 0;
    }
}


@media (max-width: $mb) {
    .header-nav-row {
        display: none;
    }

    .header-actions  {
        display: none;
    }

    .header-mobal-actions {
        display: flex;
        align-items: center;

        .action + .action {
            margin-left: 40px;
        }
    }

    .main-header-box {
        --mobalHeaderHeight: 94px;
        height: var(--mobalHeaderHeight);
    }

    .main-header {
        
        height: var(--mobalHeaderHeight);
    }
}


@media (max-width: $mx) {
    .header-mobal-actions .tel {
        display: none;
    }

    .main-header-box {
        --mobalHeaderHeight: 68px;
        height: var(--mobalHeaderHeight);
    }

    .main-header {
        height: var(--mobalHeaderHeight);
    }

    .header-main-row {
        padding: 0;

        .wrapper {
            height: var(--mobalHeaderHeight);
        }
    }

    .logo {
        width: 98px;
    }

    
}



@media (max-width: $mb) {


    .main-header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99;
    }

    .header-main-row {
        border-bottom: none;
    }


    .header-nav-row {
        display: block;
        position: fixed;
        top: var(--mobalHeaderHeight);
        right: 0;
        height: calc(100vh - var(--mobalHeaderHeight));
        height: calc(var(--vh, 1vh) * 100 - var(--mobalHeaderHeight));
        background: #FFFFFF;
        z-index: 99;
        width: 300px;
        padding: 30px 40px 30px 0;
        opacity: 0;
        visibility: hidden;
        transform: translateX(120%);
        transition: transform .3s ease;
        will-change: transform;
        overflow-y: auto;

        .wrapper {
            flex-direction: column;
            padding: 0;
            
        }

        .tel {
            display: none;
        }

        &.active {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
            transition: transform .1s ease;
          }
    }

    .main-navigation {
        align-items: flex-start;
    }

    .main-navigation ul {
        flex-direction: column;

        li {
            margin-right: 0;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        li a {
            padding: 10px 10px 10px 30px;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #1F1F1F;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 3px;
                border-radius: 0px 12px 12px 0px;
                background-color: transparent;
            }

            &.active {
                color: $accent;
                position: relative;

                &:before {
                    background-color: $accent;
                }
            }

            &:hover {
                color: $accent;
                position: relative;

                &:before {
                    background-color: $accent;
                }
            }
        }
    }


    .get-consult-btn {
        display: block;
        width: fit-content;
        margin-top: 30px;
        padding: 16px 30px;
        background-color: $accent;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #FFFFFF;
        border-radius: 0px 30px 30px 0px;
    }
}

@media (max-width: $mx) {
    .header-nav-row {
        width: 273px;
        padding-right: 20px;
    }

    


    .contacts-us-box {
        display: block;
        padding-left: 32px;
        margin-bottom: 31px;

        strong {
            display: block;
            font-size: 14px;
            line-height: 18px;
            color: #BABABA;
            margin-bottom: 8px;
            padding-left: 32px;
        }

        .tel {
            display: flex;

            img {
                margin-right: 10px;
            }
        }
    }

    .get-consult-btn {
        padding: 13px 30px;
        font-size: 16px;
        line-height: 22px;
    }

    .contacts-us-box .tel {
        padding: 0;
    }
}