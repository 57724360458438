.categories {
    padding-top: 80px;
    padding-bottom: 40px;

    .wrapper > .title {
        margin-bottom: 48px;
    }
}

.categories-block-head  {
    margin-bottom: 40px;
}

.cat-link {
    display: flex;
    align-items: center;

    span {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #1F1F1F;
        margin-right: 20px;
    }

    .ic {
        width: 54px;
        min-width: 54px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $accent;
    }
}


.category-card {
    display: block;
    height: 100%;

    .category-card-photo  {
        height: 240px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F5F6F6;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #1F1F1F;
    }
}


.categories-block  {
    padding-top: 40px;
    padding-bottom: 58px;
    border-top: 2px solid rgba(31, 31, 31, .1);
}


@media (max-width: $tb) {
    .categories {
        padding-top: 60px;
        padding-bottom: 33px;
    }

    .categories .wrapper > .title {
        margin-bottom: 28px;
    }

    .cat-link span {
        font-size: 20px;
        line-height: 28px;
        margin-right: 20px;
    }

    .categories-block {
        padding-top: 30px;
        padding-bottom: 48px;
    }

    .categories-block-head {
        margin-bottom: 30px;
    }

    .cards-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .categories-grid li:last-child {
        display: none;
    }
}


@media (max-width: $mx) {
    .categories {
        padding-top: 40px;
        padding-bottom: 24px;
    }

    .cat-link span {
        font-size: 18px;
        line-height: 24px;
    }

    .cat-link .ic {
        width: 48px;
        min-width: 48px;
        height: 48px;
    }

    .categories-block-head {
        margin-bottom: 21px;
    }

    .cards-grid {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 30px;
    }

    .category-card .category-card-photo {
        height: 180px;
        margin-bottom: 10px;
    }

    .category-card .title {
        font-size: 14px;
        line-height: 20px;
    }

    .categories-block {
        padding-top: 20px;
        padding-bottom: 37px;
    }

    .categories-grid li:last-child {
        display: block;
    }

    .categories .wrapper > .title {
        margin-bottom: 16px;
    }

    .cat-link {
        justify-content: space-between;
    }
}