
.catalog-product-page {
    padding-bottom: 60px;

    .page-title {
        margin-bottom: 60px;
    }
}

.product-detail-card {
    display: flex;
    justify-content: space-between;

    .product-detail-photos, .product-detail-body {
        width: calc(50% - 15px);
        min-width: calc(50% - 15px);
    }
}

.swiper-thumbs {
    margin-top: 10px;
    width: calc(100% + 18px);
}

.swiper-thumbs .swiper-slide {
    border: 1px solid transparent;

    &-thumb-active {
        border-color: $accent;
    }
}

.swiper-gallery .swiper-slide  {
    height: auto;
}

.swiper-gallery .swiper-slide iframe {
    width: 100%;
    height: 100%;
}

.swiper-gallery .swiper-slide img, .swiper-thumbs .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.video-thumb {
    position: relative;


    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 24px;
        height: 24px;
        background-image: url('../img/svg/video-btn.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}


.product-detail-actions {
    display: flex;

    .action + .action {
        margin-left: 20px;
    }
}

.product-detail-body > .title {
    margin-bottom: 30px;
}


.product-detail-body .product-detail-actions  {
    margin-top: 40px;
}


.product-detai-socials  {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
}

.socials-box  {
    padding-top: 10px;
}

.socials-box strong {
    display: block;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
    margin-bottom: 15px;
}



.socials-row:not(.socials-row--vertical) {
    display: flex;

    li:not(:last-child) {
        margin-right: 10px;
    }
}


.product-detail-decription  {
    padding-top: 60px;
    padding-bottom: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;

    &--single-col {
        grid-template-columns: 100%;
        grid-row-gap: 30px;
    }

    .title {
        margin-bottom: 30px;
    }

    p {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #1F1F1F;

        & + p {
            margin-top: 20px;
        }
    }
}


.product-detail-photos  .socials-row {
    display: none;
}

.socials-box--mob {
    display: none;
}

.product-detail-body-inner > .title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
}

@media (max-width: $tb) {
    .catalog-product-page {
        padding-bottom: 40px;
    }

    .product-detail-actions .accent-btn {
        padding-left: 30px;
        padding-right: 30px;
    }

    .product-detail-decription {
        grid-template-columns: 100%;
        grid-row-gap: 40px;
    }

    .catalog-product-page .page-title {
        margin-bottom: 50px;
    }

    .product-detail-body > .title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 20px;
    }

    .product-detail-body  .characteristics li:not(:last-child) {
        margin-bottom: 10px;
    }

    .product-detail-body .product-detail-actions {
        margin-top: 28px;
    }

    .socials-box strong {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 15px;
    }

    .product-detai-socials {
        margin-top: 30px;
    }

    .manufacturer {
        width: 80px;
        height: 80px;
        margin-top: 10px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .swiper-thumbs {
        width: 100%;
    }

    .product-detail-decription {
        padding-top: 40px;
    }

    .product-detail-decription .title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 20px;
    }

    .product-detail-decription p {
        font-size: 16px;
        line-height: 24px;
    }

    .product-detail-body-inner > .title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 20px;
    }
}


@media (min-width: calc($mb + 1px)) and (max-width: 1000px) {
    .product-detail-body .product-detail-actions {
        flex-direction: column;

        .accent-btn {
            width: 100%;
        }
    }

    .product-detail-actions .action + .action {
        margin-left: 0;
        margin-top: 20px;
    }
}



@media (min-width: 577px) and (max-width: $mb) {
    .swiper-thumbs .swiper-slide {
        height: 80px;
    }

    .swiper-thumbs {
        width: 80px;
        min-width: 80px;
        margin-right: 10px !important;
        margin-top: 0 !important;
    }

    .swiper-gallery {
        flex: 1;
    }

    .product-detail-photos .socials-row {
        display: block;
        order: 2;
        margin-left: 48px;
    }

    .product-detail-card .socials-row--vertical li:not(:last-child) {
        margin-bottom: 10px;
        margin-right: 0;
    }
}

@media (max-width: $mb) {
    .product-detail-card {
        flex-direction: column;
    }

    .product-detail-card .product-detail-photos, .product-detail-card .product-detail-body {
        width: 100%;
    }

    .product-detail-card .product-detail-photos {
        margin-bottom: 40px;
    }

    .product-detail-photos {
        display: flex;

        .swiper-gallery  {
            order: 1;
        }
    }

    .product-detai-socials .socials-box {
        display: none ;
    }

    .product-detail-body {
        display: grid;
        grid-template-columns: 1fr 80px;
        grid-column-gap: 30px;
    }

    .manufacturer {
        margin-top: 0;
    }

    .product-detail-body-inner > .title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 20px;
    }

    .product-detai-socials {
        margin-top: 0;
    }

    .product-detail-body .characteristics li span {
        font-size: 14px;
        line-height: 20px;
        margin-right: 10px;
        margin-top: 0;
    }

    .product-detail-body .characteristics li strong {
        font-size: 14px;
        line-height: 20px;
        margin-top: 0;
    }
}


@media (max-width: $mx) {
    .product-detail-photos {
        flex-direction: column;
    }

    .product-detail-photos .swiper-gallery {
        order: 1;
        width: 100%;
        max-width: 100%;
    }

    .swiper-thumbs {
        order: 2;
    }

    .manufacturer {
        display: none;
    }

    .product-detail-body {
        grid-template-columns: 100%;
    }

    .socials-box--mob  {
        display: block;
        padding-top: 30px;
        padding-bottom: 20px;
        border-top: 1px solid rgba(31, 31, 31, .1);
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .catalog-product-page .page-title {
        margin-bottom: 30px;
    }

    .swiper-thumbs {
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .swiper-thumbs .swiper-slide {
        width: 60px;
        height: 60px;
    }

    .product-detail-card .product-detail-photos {
        margin-bottom: 30px;
    }

    .product-detail-body-inner > .title {
        font-size: 18px;
        line-height: 24px;
    }

    .product-detail-body .characteristics li:not(:last-child) {
        margin-bottom: 0;
    }

    .product-detail-body .characteristics li:not(:first-child) span, .product-detail-body .characteristics li:not(:first-child) strong {
        margin-top: 6px;
    }

    .product-detail-body .product-detail-actions {
        flex-direction: column;

        .accent-btn {
            width: 100%;
        }
    }

    .product-detail-actions .action + .action {
        margin-left: 0;
        margin-top: 20px;
    }

    .product-detail-actions .accent-btn {
        padding: 13px 30px;
    }

    .product-detail-decription .title {
        font-size: 18px;
        line-height: 24px;
    }

    .product-detail-decription {
        padding-top: 30px;
    }

    .product-detail-decription p {
        font-size: 14px;
        line-height: 20px;
    }

    .product-detail-decription p + p {
        margin-top: 10px;
    }

    .product-detail-decription {
        grid-row-gap: 30px;
    }

    .product-detail-decription {
        padding-bottom: 30px;
    }

    .socials-box strong {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .socials-box--mob .socials-row:not(.socials-row--vertical) {
        justify-content: space-between;
    }

    .socials-box--mob .socials-row:not(.socials-row--vertical) li:not(:last-child) {
        margin-right: 0;
    }

}