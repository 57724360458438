/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: 'Golos Text';
  src: url("../fonts/Golos-Text_Black.woff2") format("woff2"), url("../fonts/Golos-Text_Black.woff") format("woff"), url("../fonts/Golos-Text_Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Golos Text';
  src: url("../fonts/Golos-Text_Bold.woff2") format("woff2"), url("../fonts/Golos-Text_Bold.woff") format("woff"), url("../fonts/Golos-Text_Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Golos Text';
  src: url("../fonts/Golos-Text_DemiBold.woff2") format("woff2"), url("../fonts/Golos-Text_DemiBold.woff") format("woff"), url("../fonts/Golos-Text_DemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Golos Text';
  src: url("../fonts/Golos-Text_Medium.woff2") format("woff2"), url("../fonts/Golos-Text_Medium.woff") format("woff"), url("../fonts/Golos-Text_Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Golos Text';
  src: url("../fonts/Golos-Text_Regular.woff2") format("woff2"), url("../fonts/Golos-Text_Regular.woff") format("woff"), url("../fonts/Golos-Text_Medium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

input {
  outline: none; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

* {
  box-sizing: border-box;
  outline: none; }

.nav-btn span {
  display: block; }

button, a {
  cursor: pointer;
  transition: all .3s ease;
  border: none;
  background: none;
  text-decoration: none; }

button {
  font-family: "Golos Text", sans-serif; }

textarea {
  resize: vertical;
  font-family: "Golos Text", sans-serif; }

input, textarea {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1D1D1F; }
  input::placeholder, textarea::placeholder {
    font-family: "Golos Text", sans-serif;
    opacity: 1; }

body {
  font-family: "Golos Text", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #1D1D1F;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden; }

.wrapper {
  max-width: 1320px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }
  .wrapper--xs {
    max-width: 990px; }
  .wrapper.ui-wrapper {
    max-width: 100%; }

main {
  flex: 1; }

img {
  display: block;
  max-width: 100%; }

.ic-btn {
  padding: 17px;
  background: rgba(230, 42, 42, 0.1);
  border-radius: 30px; }
  .ic-btn--accent {
    background-color: #E62A2A; }

.accent-btn {
  display: block;
  width: fit-content;
  padding: 16px 40px;
  background-color: #E62A2A;
  border-radius: 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF; }
  .accent-btn--pale {
    background: rgba(230, 42, 42, 0.1);
    color: #E62A2A;
    border: 1px solid transparent; }

.tel {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #1F1F1F; }
  .tel img {
    margin-right: 15px; }
  .tel--white {
    color: #fff; }

.rounded-ic {
  width: 54px;
  min-width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F5F6F6;
  border-radius: 27px; }

ymaps[class*="ground-pane"] > ymaps > * {
  filter: grayscale(1) !important;
  -ms-filter: grayscale(1) !important;
  -webkit-filter: grayscale(1) !important;
  -moz-filter: grayscale(1) !important;
  -o-filter: grayscale(1) !important; }

.raunded-btn {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 16px 40px;
  background-color: #E62A2A;
  border-radius: 30px 30px 0px 0px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF; }
  .raunded-btn span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF; }
  .raunded-btn svg {
    margin-left: 10px; }

.input-wrap {
  position: relative; }
  .input-wrap input, .input-wrap textarea {
    display: block;
    width: 100%;
    padding: 24px 0 10px 0;
    border: none;
    border-bottom: 2px solid #BABABA;
    background: none;
    font-size: 'Golos Text', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF; }
  .input-wrap input {
    height: 54px; }
  .input-wrap textarea {
    min-height: 120px;
    resize: vertical; }
  .input-wrap label {
    position: absolute;
    top: 22px;
    left: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #BABABA;
    transition: all .3s ease;
    cursor: text; }
  .input-wrap--white label {
    color: #fff; }
  .input-wrap--white input {
    border-color: #fff; }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(31, 31, 31, 0.4);
  backdrop-filter: blur(3px);
  z-index: 98;
  opacity: 0;
  visibility: hidden; }
  .overlay.active {
    display: block;
    opacity: 1;
    visibility: visible;
    transition: all .5s ease; }

.no-scroll {
  overflow: hidden; }

.input-wrap.active label {
  font-size: 12px;
  line-height: 16px;
  color: #BABABA;
  top: 0; }

.inputs-row {
  display: grid; }
  .inputs-row--t2 {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px; }
  .inputs-row + .inputs-row {
    margin-top: 40px; }

.form-inputs {
  margin-bottom: 46px; }

.more-btn {
  display: flex;
  align-items: center;
  width: fit-content; }
  .more-btn span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #E62A2A; }
  .more-btn .ic {
    width: 54px;
    height: 54px;
    min-width: 54px;
    background-color: #E62A2A;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    border-radius: 50%; }

sup {
  font-size: 85%;
  vertical-align: super;
  line-height: 96%; }

.nav-buttons {
  display: flex;
  width: fit-content; }

.nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E62A2A;
  padding: 17px;
  width: fit-content; }
  .nav-button.prev {
    border-radius: 0px 30px 0px 0px;
    transform: matrix(-1, 0, 0, 1, 0, 0); }
  .nav-button.next {
    border-radius: 0px 30px 0px 0px; }

h1 {
  font-weight: bold;
  font-size: 44px;
  line-height: 54px;
  color: #1F1F1F; }

h2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  color: #1F1F1F; }

h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #000000; }

.cards-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 40px; }

.sidemarginauto {
  margin-left: auto;
  margin-right: auto; }

.text-content p {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1F1F1F; }
  .text-content p + p {
    margin-top: 21px; }

.text-content ul {
  margin-top: 40px;
  margin-bottom: 40px; }
  .text-content ul li:not(:last-child) {
    margin-bottom: 10px; }
  .text-content ul li {
    position: relative;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
    padding-left: 28px; }
    .text-content ul li::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #E62A2A; }

.text-content .img-wrap {
  width: 100%;
  margin-top: 60px; }

.dk-bg {
  background: #F5F6F6; }

.socials-row--vertical li:not(:last-child) {
  margin-bottom: 20px; }

.accent-btn:hover, .nav-button:hover, .raunded-btn:hover, .sld-nav-btn:hover, .pagination .ic-btn:hover {
  opacity: 1;
  background-color: #CE0000; }

.accent-btn--pale:hover {
  background: no-repeat;
  border-color: #E62A2A; }

.btn-in-center {
  margin-left: auto;
  margin-right: auto; }

.more-btn:hover {
  opacity: 1; }
  .more-btn:hover span {
    text-decoration-line: underline; }
  .more-btn:hover .ic {
    background-color: #CE0000; }

.cat-link:hover .ic {
  background-color: #CE0000; }

.accent-btn:disabled, .raunded-btn:disabled {
  color: #1F1F1F;
  background: none;
  border: 1px solid #BABABA; }

.has-sidebar {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-column-gap: 30px; }

.check-block {
  display: flex; }

.check-block .checkbox {
  width: 18px;
  min-width: 18px;
  height: 18px;
  border-radius: 4px;
  position: relative;
  margin-right: 12px;
  margin-top: 2px;
  padding: 0; }

.check-block .checkbox input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer; }

.check-block .checkbox .checkbox-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  -webkit-transition: background-color .3s ease;
  -o-transition: background-color .3s ease;
  transition: background-color .3s ease;
  border-radius: 4px;
  border: 2px solid rgba(31, 31, 31, 0.4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.check-block .checkbox .checkbox-mask img {
  opacity: 0;
  -webkit-transition: opacity .3s ease;
  -o-transition: opacity .3s ease;
  transition: opacity .3s ease; }

.check-block .checkbox input:checked ~ .checkbox-mask {
  background: #E62A2A;
  border-color: #E62A2A; }

.check-block .checkbox input:checked ~ .checkbox-mask img {
  opacity: 1; }

.check-block label {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #0A2540;
  cursor: pointer; }
  .check-block label a {
    color: #E62A2A; }

@media (max-width: 1024px) {
  .wrapper {
    padding-left: 60px;
    padding-right: 60px; }
  h1 {
    font-size: 36px;
    line-height: 48px; }
  h2 {
    font-size: 28px;
    line-height: 40px; } }

@media (max-width: 880px) {
  .wrapper {
    padding-left: 40px;
    padding-right: 40px; } }

@media (max-width: 577px) {
  .wrapper {
    padding-left: 20px;
    padding-right: 20px; }
  .ic-btn {
    padding: 0;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center; }
  h1 {
    font-size: 24px;
    line-height: 32px; }
  h2 {
    font-size: 20px;
    line-height: 28px; }
  .raunded-btn {
    padding: 14px 30px; }
    .raunded-btn span {
      font-size: 16px;
      line-height: 22px; }
  .inputs-row + .inputs-row {
    margin-top: 30px; }
  .inputs-row--t2 {
    grid-template-columns: 100%;
    grid-row-gap: 30px; } }

.main-header {
  background-color: #fff;
  box-shadow: 0px 6px 40px rgba(31, 31, 31, 0.05); }

.header-main-row {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(31, 31, 31, 0.1); }
  .header-main-row .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center; }

.logo {
  display: flex;
  align-items: center; }

.logo-descr {
  margin-left: 40px; }
  .logo-descr p {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #BABABA; }
    .logo-descr p + p {
      margin-top: 4px; }

.header-actions {
  display: flex; }
  .header-actions .action + .action {
    margin-left: 20px; }
  .header-actions .action {
    height: 54px; }

.main-navigation {
  display: flex;
  justify-content: space-between; }
  .main-navigation .tel {
    padding-bottom: 30px;
    padding-top: 20px; }
  .main-navigation ul li a {
    padding-top: 20px;
    padding-bottom: 32px;
    position: relative; }
    .main-navigation ul li a:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      border-radius: 12px 12px 0px 0px;
      background-color: transparent; }
    .main-navigation ul li a.active {
      color: #E62A2A;
      position: relative; }
      .main-navigation ul li a.active:before {
        background-color: #E62A2A; }
    .main-navigation ul li a:hover {
      color: #E62A2A;
      position: relative; }
      .main-navigation ul li a:hover:before {
        background-color: #E62A2A; }

.header-nav-row ul {
  display: flex; }
  .header-nav-row ul li:not(:last-child) {
    margin-right: 20px; }
  .header-nav-row ul a {
    display: block;
    padding: 0 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #1F1F1F; }

.header-mobal-actions {
  display: none; }

.get-consult-btn {
  display: none; }

.contacts-us-box {
  display: none; }

.menu-btn .act {
  display: none; }

.menu-btn.active .act {
  display: block; }

.menu-btn.active .def {
  display: none; }

@media (max-width: 1024px) {
  .logo-descr {
    margin-left: 30px; }
  .header-nav-row ul li:not(:last-child) {
    margin-right: 10.5px; } }

@media (max-width: 880px) {
  .header-actions .action.accent-btn {
    padding-left: 20px;
    padding-right: 20px; }
  .logo-descr {
    display: none; } }

@media (max-width: 800px) {
  .header-nav-row ul a {
    padding: 0; } }

@media (max-width: 768px) {
  .header-nav-row {
    display: none; }
  .header-actions {
    display: none; }
  .header-mobal-actions {
    display: flex;
    align-items: center; }
    .header-mobal-actions .action + .action {
      margin-left: 40px; }
  .main-header-box {
    --mobalHeaderHeight: 94px;
    height: var(--mobalHeaderHeight); }
  .main-header {
    height: var(--mobalHeaderHeight); } }

@media (max-width: 577px) {
  .header-mobal-actions .tel {
    display: none; }
  .main-header-box {
    --mobalHeaderHeight: 68px;
    height: var(--mobalHeaderHeight); }
  .main-header {
    height: var(--mobalHeaderHeight); }
  .header-main-row {
    padding: 0; }
    .header-main-row .wrapper {
      height: var(--mobalHeaderHeight); }
  .logo {
    width: 98px; } }

@media (max-width: 768px) {
  .main-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99; }
  .header-main-row {
    border-bottom: none; }
  .header-nav-row {
    display: block;
    position: fixed;
    top: var(--mobalHeaderHeight);
    right: 0;
    height: calc(100vh - var(--mobalHeaderHeight));
    height: calc(var(--vh, 1vh) * 100 - var(--mobalHeaderHeight));
    background: #FFFFFF;
    z-index: 99;
    width: 300px;
    padding: 30px 40px 30px 0;
    opacity: 0;
    visibility: hidden;
    transform: translateX(120%);
    transition: transform .3s ease;
    will-change: transform;
    overflow-y: auto; }
    .header-nav-row .wrapper {
      flex-direction: column;
      padding: 0; }
    .header-nav-row .tel {
      display: none; }
    .header-nav-row.active {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
      transition: transform .1s ease; }
  .main-navigation {
    align-items: flex-start; }
  .main-navigation ul {
    flex-direction: column; }
    .main-navigation ul li {
      margin-right: 0; }
      .main-navigation ul li:not(:last-child) {
        margin-bottom: 10px; }
    .main-navigation ul li a {
      padding: 10px 10px 10px 30px;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #1F1F1F; }
      .main-navigation ul li a:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 3px;
        border-radius: 0px 12px 12px 0px;
        background-color: transparent; }
      .main-navigation ul li a.active {
        color: #E62A2A;
        position: relative; }
        .main-navigation ul li a.active:before {
          background-color: #E62A2A; }
      .main-navigation ul li a:hover {
        color: #E62A2A;
        position: relative; }
        .main-navigation ul li a:hover:before {
          background-color: #E62A2A; }
  .get-consult-btn {
    display: block;
    width: fit-content;
    margin-top: 30px;
    padding: 16px 30px;
    background-color: #E62A2A;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    border-radius: 0px 30px 30px 0px; } }

@media (max-width: 577px) {
  .header-nav-row {
    width: 273px;
    padding-right: 20px; }
  .contacts-us-box {
    display: block;
    padding-left: 32px;
    margin-bottom: 31px; }
    .contacts-us-box strong {
      display: block;
      font-size: 14px;
      line-height: 18px;
      color: #BABABA;
      margin-bottom: 8px;
      padding-left: 32px; }
    .contacts-us-box .tel {
      display: flex; }
      .contacts-us-box .tel img {
        margin-right: 10px; }
  .get-consult-btn {
    padding: 13px 30px;
    font-size: 16px;
    line-height: 22px; }
  .contacts-us-box .tel {
    padding: 0; } }

.main-footer {
  background: #F5F6F6; }

.contact-column {
  padding: 80px 40px 60px 40px;
  max-width: 520px;
  width: 100%;
  background: #FFFFFF;
  position: relative;
  z-index: 2; }

.contact-column-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px; }

.socials {
  display: flex; }
  .socials li:not(:last-child) {
    margin-right: 20px; }

.contacts-list-item {
  display: flex; }
  .contacts-list-item .ic {
    margin-right: 30px;
    margin-top: 14px; }

.contacts-list .contacts-list-item:not(:last-child) {
  margin-bottom: 30px; }

.contacts-list-item-text {
  flex: 1; }
  .contacts-list-item-text span {
    display: block;
    font-size: 14px;
    line-height: 18px;
    color: #BABABA;
    margin-bottom: 10px; }
  .contacts-list-item-text p, .contacts-list-item-text a {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F; }

.main-footer .wrapper {
  display: flex; }

.footer-menus-col {
  flex: 1;
  padding: 80px 0 251px 80px;
  display: flex;
  flex-direction: column; }

.footer-menus {
  display: flex;
  margin-bottom: 40px; }
  .footer-menus .footer-menu:not(:last-child) {
    margin-right: 30px; }

.footer-menu {
  max-width: 330px;
  width: 100%; }
  .footer-menu .lbl {
    display: block;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #1F1F1F;
    margin-bottom: 30px; }
  .footer-menu ul li:not(:last-child) {
    margin-bottom: 10px; }
  .footer-menu ul li a {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F; }

.copy {
  margin-top: auto;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #BABABA; }

.map {
  height: 400px;
  position: relative;
  margin-top: -171px;
  z-index: 1; }

@media (max-width: 1024px) {
  .main-footer .wrapper {
    padding-left: 0; }
  .contact-column {
    padding: 60px 30px 40px 60px;
    max-width: 497px; }
  .socials li img {
    width: 20px; }
  .contact-column-head {
    margin-bottom: 40px; }
  .contacts-list-item-text span {
    font-size: 12px;
    line-height: 16px;
    color: #BABABA; }
  .contacts-list-item-text p, .contacts-list-item-text a {
    font-size: 14px;
    line-height: 20px; }
  .contacts-list-item .ic {
    margin-right: 20px;
    margin-top: 9px; }
  .contacts-list .contacts-list-item:not(:last-child) {
    margin-bottom: 20px; }
  .footer-menus-col {
    padding: 60px 0 138px 29px; }
  .footer-menu .lbl {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 30px; }
  .footer-menu ul li a {
    font-size: 14px;
    line-height: 20px; }
  .footer-menus {
    margin-bottom: 30px; }
  .footer-menu {
    max-width: 201px; }
  .map {
    margin-top: -110px; }
  .copy {
    font-size: 12px;
    line-height: 18px; } }

@media (max-width: 768px) {
  .main-footer .wrapper {
    flex-direction: column;
    width: 100%; }
  .contact-column {
    max-width: calc(100% + 80px);
    padding: 60px 40px 40px 40px;
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-right: -40px; }
  .main-footer .wrapper {
    padding-left: 40px; }
  .contacts-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px; }
  .contacts-list .contacts-list-item:not(:last-child) {
    margin-bottom: 0; }
  .footer-menus-col {
    padding: 40px 0 40px 0; }
  .footer-menu {
    max-width: 334px; }
  .footer-menus .footer-menu:not(:last-child) {
    margin-right: 20px; }
  .footer-menu .lbl {
    margin-bottom: 18px; }
  .map {
    margin-top: 0; } }

@media (max-width: 577px) {
  .main-footer .wrapper {
    padding-left: 20px; }
  .contact-column {
    max-width: calc(100% + 40px);
    padding: 37px 20px 30px 20px;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px; }
  .contacts-list {
    grid-template-columns: 100%;
    grid-column-gap: 20px;
    grid-row-gap: 15px; }
  .contact-column-head {
    margin-bottom: 32px; }
    .contact-column-head .socials {
      margin-top: 8px; }
  .contacts-list-item .ic {
    width: 48px;
    min-width: 48px;
    height: 48px;
    margin-right: 16px; }
  .contacts-list-item-text span {
    margin-bottom: 6px; }
  .footer-menus {
    flex-direction: column; }
  .footer-menus .footer-menu:not(:last-child) {
    margin-right: 0;
    margin-bottom: 30px; }
  .footer-menus-col {
    padding: 30px 0 30px 0; }
  .footer-menu .lbl {
    margin-bottom: 14px; }
  .footer-menu ul li:not(:last-child) {
    margin-bottom: 8px; } }

.ui-content {
  padding: 40px 0;
  display: grid;
  grid-template-columns: 256px 1fr;
  gap: 24px 24px; }

#ui-menu {
  position: sticky;
  top: 24px; }
  #ui-menu ul li:not(:last-child) {
    margin-bottom: 20px; }
  #ui-menu ul a {
    display: block;
    white-space: nowrap; }

.ui-sidebar {
  padding-top: 40px; }

.ui-box {
  padding: 40px 0;
  border-bottom: 1px solid #333;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px 24px; }

.ui-box-title {
  font-size: 24px;
  line-height: 120%;
  font-weight: bold;
  margin-bottom: 32px; }

.pagination .prev svg {
  transform: matrix(-1, 0, 0, 1, 0, 0); }

.pagination {
  display: flex;
  align-items: center;
  margin-top: 60px; }
  .pagination ul {
    display: flex;
    align-items: center; }
    .pagination ul li:not(:last-child) {
      margin-right: 10px; }
    .pagination ul a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      min-width: 54px;
      height: 54px;
      border-radius: 50%;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #E62A2A; }
      .pagination ul a.current {
        color: #1F1F1F;
        background: #F5F6F6; }
      .pagination ul a:not(.current):hover {
        background: rgba(230, 42, 42, 0.1); }

.pagination .prev {
  margin-right: 10px;
  height: 54px;
  display: none; }

.pagination .next {
  margin-left: 10px;
  height: 54px; }

@media (max-width: 1024px) {
  .pagination {
    margin-top: 40px; } }

@media (max-width: 577px) {
  .pagination {
    margin-top: 18px;
    justify-content: space-between; }
  .pagination .prev, .pagination .next {
    width: 48px;
    height: 48px;
    min-width: 48px; }
  .pagination ul li:not(:last-child) {
    margin-right: 0; }
  .pagination ul a {
    width: 48px;
    min-width: 48px;
    height: 48px; } }

.filter-block-title {
  display: block;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #1F1F1F; }
  .filter-block-title + * {
    margin-top: 20px; }

.checks-list li:not(:last-child) {
  margin-bottom: 10px; }

.checks-block--accent {
  padding: 20px;
  background: #F5F6F6; }

.filter-box + .filter-box {
  margin-top: 40px; }

.filter-box ul:not(.checks-list) li:not(:last-child) {
  margin-bottom: 20px; }

.filter-box ul:not(.checks-list) li a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1F1F1F; }

.filter-box ul.level-2 {
  padding-left: 20px;
  margin-top: 15px;
  display: none; }
  .filter-box ul.level-2 li:not(:last-child) {
    margin-bottom: 10px; }
  .filter-box ul.level-2 li a {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #1F1F1F; }
    .filter-box ul.level-2 li a:hover {
      color: #E62A2A; }

li.has-submenu {
  position: relative; }
  li.has-submenu:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #F5F6F6;
    background-image: url("../img/svg/icon-plus.svg");
    background-position: center center;
    background-repeat: no-repeat; }
  li.has-submenu.active:after {
    background-image: url("../img/svg/icon-minus.svg"); }

.filter {
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

@media (max-width: 1024px) {
  .filter {
    padding-bottom: 35px; }
  .filter-box + .filter-box {
    margin-top: 30px; }
  .filter-block-title {
    font-size: 16px;
    line-height: 22px; }
  .filter-block-title + * {
    margin-top: 15px; }
  .filter-box ul.level-2 {
    padding-right: 20px; }
  .filter-box ul.level-2 li a {
    word-break: break-word; }
  .filter-box ul:not(.checks-list) li:not(:last-child) {
    margin-bottom: 15px; }
  .checks-block .filter-block-title + * {
    margin-top: 20px; }
  .filter-box ul.level-2 li:not(:last-child) {
    margin-bottom: 10px; } }

@media (max-width: 768px) {
  .filter {
    padding-bottom: 28px; } }

.files-col {
  padding-top: 40px;
  padding-bottom: 40px; }

.files-col ul li:not(:last-child) {
  margin-bottom: 20px; }

.file-link {
  display: flex;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #1F1F1F; }
  .file-link .ic {
    width: 20px;
    height: 20px;
    min-width: 20px;
    margin-right: 12px; }

@media (max-width: 1024px) {
  .files-col {
    padding-top: 30px;
    padding-bottom: 40px; } }

@media (max-width: 768px) {
  .files-col {
    padding-top: 31px;
    padding-bottom: 10px; } }

.get-consult {
  padding: 80px 58px 60px 60px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(15px);
  height: fit-content; }
  .get-consult .title {
    color: #FFFFFF;
    margin-bottom: 20px; }
  .get-consult p {
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF; }
    .get-consult p + p {
      margin-top: 20px; }
  .get-consult .inputs-row {
    margin-top: 30px; }
  .get-consult .accent-btn {
    margin-top: 40px;
    width: 100%; }

@media (max-width: 1024px) {
  .get-consult {
    padding: 60px 60px 40px 60px;
    max-width: 400px; }
  .get-consult .title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px; }
  .get-consult p {
    font-size: 14px;
    line-height: 20px; } }

@media (max-width: 768px) {
  .get-consult {
    padding: 30px;
    max-width: 100%; }
  .get-consult {
    display: grid;
    grid-template-columns: 1fr 240px;
    grid-column-gap: 30px; }
    .get-consult .inputs-row {
      margin-top: 0; }
    .get-consult .accent-btn {
      margin-top: 30px; } }

@media (max-width: 577px) {
  .get-consult {
    grid-template-columns: 100%;
    grid-row-gap: 30px;
    width: calc(100% + 40px);
    max-width: none;
    margin-left: -20px;
    margin-right: -20px; }
    .get-consult .accent-btn {
      padding: 13px 40px; } }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: transform .3s ease 0; }
  .modal.active {
    opacity: 1;
    visibility: visible;
    transition: transform .1s ease .4; }
    .modal.active .modal-inner {
      opacity: 1;
      visibility: visible;
      transform: translate(-50%, 0); }

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(31, 31, 31, 0.4);
  backdrop-filter: blur(3px);
  z-index: 1; }

.modal-inner {
  position: absolute;
  top: 20.53%;
  left: 50%;
  transform: translate(-50%, 50px);
  max-width: 850px;
  width: 100%;
  padding: 60px 60px 0 60px;
  background: #fff;
  transition: all .3s ease .3s;
  will-change: transform;
  opacity: 0;
  visibility: hidden;
  z-index: 2; }
  .modal-inner .submit-btn {
    margin-top: 60px; }
  .modal-inner .form-inputs {
    margin-bottom: 42px; }

.modal-title {
  margin-bottom: 60px; }

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 54px;
  height: 54px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(230, 42, 42, 0.1);
  border-radius: 0px 0px 0px 30px; }

@media (max-width: 1024px) {
  .modal {
    display: flex;
    justify-content: center;
    overflow-y: auto;
    padding-bottom: 60px; }
  .modal-overlay {
    position: fixed; }
  .modal-inner {
    margin-top: 134px;
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0, 50px);
    height: fit-content;
    max-width: 748px;
    padding-top: 50px; }
  .modal.active .modal-inner {
    transform: translate(0, 0); }
  .modal-title {
    margin-bottom: 40px; }
  .modal-inner .submit-btn {
    margin-top: 40px; } }

@media (max-width: 768px) {
  .modal {
    z-index: 100; }
  .modal-inner {
    margin-top: 34%;
    width: calc(100% - 80px);
    max-width: none;
    padding-top: 60px; }
  .modal-title {
    margin-bottom: 30px; } }

@media (max-width: 577px) {
  .modal {
    padding-bottom: 0;
    overflow-y: hidden; }
  .modal-inner {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    width: 100%;
    height: 100%;
    padding-top: 60px;
    overflow-y: auto;
    padding: 40px 20px 0 20px;
    transform: translate(0, 10px); }
    .modal-inner .callback-form {
      flex: 1; }
  .modal-inner .check-block {
    margin-bottom: 40px; }
  .modal-inner .submit-btn {
    margin-top: auto; }
  .modal-title {
    margin-bottom: 70px; }
  .modal-inner .form-inputs {
    margin-bottom: 30px; }
  .modal-inner .raunded-btn {
    padding: 16px 40px; }
  .modal-close {
    width: 48px;
    height: 48px; } }

.main-slider {
  background-color: rgba(31, 31, 31, 0); }

.main-slider .swiper-slide {
  position: relative;
  padding-top: 60px;
  padding-bottom: 116px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }
  .main-slider .swiper-slide:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(31, 31, 31, 0.95) 0%, rgba(31, 31, 31, 0.7) 43.23%, rgba(31, 31, 31, 0) 100%); }
  .main-slider .swiper-slide > * {
    position: relative;
    z-index: 1; }

.slide-content {
  max-width: 410px; }
  .slide-content .title {
    display: block;
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    color: #FFFFFF;
    margin-bottom: 30px; }
    .slide-content .title sup {
      display: inline-block;
      transform: translateY(5px); }
  .slide-content p {
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: #FFFFFF; }
  .slide-content .more-btn {
    margin-top: 30px; }

.main-slider {
  position: relative; }

.main-slider .nav-row {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 12; }
  .main-slider .nav-row .wrapper {
    display: flex;
    justify-content: flex-end; }

.swiper-pagination {
  width: fit-content !important;
  position: absolute;
  left: 50% !important;
  transform: translateX(-50%) !important;
  bottom: 30px !important;
  z-index: 13; }
  .swiper-pagination .swiper-pagination-bullet {
    margin: 0 14px !important;
    background: #FFFFFF;
    width: 8px;
    height: 8px;
    opacity: 1; }
    .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background: #E62A2A;
      position: relative; }
      .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 16px;
        height: 16px;
        background-color: #E62A2A;
        border-radius: 50%; }

@media (max-width: 1024px) {
  .slide-content .title {
    font-size: 28px;
    line-height: 40px; }
  .slide-content {
    max-width: 437px; }
  .slide-content p {
    font-size: 16px;
    line-height: 24px; }
  .main-slider .swiper-slide {
    padding-bottom: 158px; }
  .swiper-pagination .swiper-pagination-bullet {
    margin: 0 9px !important; }
  .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
    width: 12px;
    height: 12px; }
  .main-slider .swiper-pagination {
    bottom: 28px !important; } }

@media (max-width: 577px) {
  .slide-content .title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px; }
  .main-slider .swiper-slide {
    padding-top: 30px; }
  .slide-content p {
    font-size: 14px;
    line-height: 20px; }
  .slide-content .more-btn {
    margin-top: 18px; }
  .main-slider .more-btn .ic {
    width: 48px;
    height: 48px;
    min-width: 48px; }
  .main-slider .swiper-slide {
    min-height: 500px; }
  .nav-button {
    padding: 14px; }
  .main-slider .swiper-pagination {
    bottom: 18px !important; }
  body .main-slider .swiper-pagination {
    left: 15px !important;
    transform: none !important;
    transform: none; } }

.callback-section {
  padding-top: 80px;
  background-image: url("../img/callback-bg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }
  .callback-section .wrapper {
    display: grid;
    grid-template-columns: 410px 1fr;
    grid-column-gap: 140px; }

.callback-section-title-col {
  padding-bottom: 80px; }
  .callback-section-title-col .title {
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    color: #FFFFFF;
    margin-bottom: 30px; }
  .callback-section-title-col p {
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: #FFFFFF; }
    .callback-section-title-col p + p {
      margin-top: 15px; }
  .callback-section-title-col .box {
    margin-top: 30px; }
    .callback-section-title-col .box strong {
      display: block;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: #FFFFFF;
      margin-bottom: 20px; }
    .callback-section-title-col .box .tel span {
      font-size: 24px;
      line-height: 32px; }

.callback-form {
  display: flex;
  flex-direction: column; }
  .callback-form .submit-btn {
    margin-left: auto; }

.btn-in-bottom {
  margin-top: auto; }

.callback-section--t2 .wrapper {
  display: grid;
  grid-template-columns: 1fr 410px;
  grid-column-gap: 100px; }
  .callback-section--t2 .wrapper .form-inputs {
    margin-bottom: 40px; }

@media (max-width: 1024px) {
  .callback-section {
    padding-top: 60px; }
  .callback-section--t2 .wrapper {
    grid-template-columns: 1fr 281px; }
  .callback-section-title-col .title {
    font-size: 28px;
    line-height: 40px; }
  .callback-section-title-col p {
    font-size: 16px;
    line-height: 24px; }
  .callback-section--t2 .callback-section-title-col .title {
    max-width: 437px; }
  .callback-section--t2 .callback-section-title-col p {
    max-width: 275px; }
  .callback-section:not(.callback-section--t2) .wrapper {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px; }
    .callback-section:not(.callback-section--t2) .wrapper .callback-section-title-col p {
      max-width: 316px; }
    .callback-section:not(.callback-section--t2) .wrapper .callback-section-title-col .box strong {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 11px; }
  .callback-section-title-col .box .tel span {
    font-size: 20px;
    line-height: 28px; }
  .form-inputs {
    margin-bottom: 40px; }
  .callback-section--t3 .wrapper {
    grid-template-columns: 1fr 440px; }
    .callback-section--t3 .wrapper .inputs-row + .inputs-row {
      margin-top: 30px; } }

@media (max-width: 768px) {
  .callback-section--t2 .wrapper {
    grid-template-columns: 1fr 245px; }
  .callback-section:not(.callback-section--t2) .wrapper {
    grid-template-columns: 100%;
    grid-row-gap: 50px; }
  .callback-section:not(.callback-section--t2) .wrapper .callback-section-title-col p {
    max-width: 100%; }
  .callback-section-title-col {
    padding-bottom: 0px; } }

@media (max-width: 577px) {
  .callback-section--t2 {
    padding-top: 40px; }
  .callback-section--t2 .wrapper {
    grid-template-columns: 100%;
    grid-row-gap: 40px; }
  .callback-section-title-col .title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px; }
  .callback-section--t2 .callback-section-title-col p {
    font-size: 14px;
    line-height: 20px; }
  .callback-section--t2 .callback-section-title-col p {
    max-width: 240px; }
  .callback-section--t2 .wrapper .form-inputs {
    margin-bottom: 28px; }
  .callback-section {
    padding-top: 40px; }
  .callback-section:not(.callback-section--t2) .wrapper .callback-section-title-col p {
    font-size: 14px;
    line-height: 20px; }
  .callback-section:not(.callback-section--t2) .wrapper .callback-section-title-col .box strong {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px; }
  .callback-section-title-col .box {
    margin-top: 20px; }
  .callback-section:not(.callback-section--t2) .wrapper {
    grid-row-gap: 36px; }
  .form-inputs {
    margin-bottom: 30px; } }

.about-company {
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden; }
  .about-company .wrapper {
    display: flex;
    align-items: center; }

.about-company-text-content {
  max-width: 480px;
  width: 100%;
  margin-right: 100px;
  position: relative;
  z-index: 2; }

.about-company-text-content .title {
  margin-bottom: 30px; }

.about-company-text-content p {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1F1F1F; }
  .about-company-text-content p + p {
    margin-top: 15px; }

.about-company-text-content .more-btn {
  margin-top: 30px; }

#circle {
  --circleSize: 710px; }

#circle {
  position: relative;
  height: var(--circleSize);
  width: var(--circleSize);
  min-width: var(--circleSize); }

.circle {
  width: 120px;
  height: 120px;
  position: absolute;
  background-color: #E62A2A; }

#circle > * {
  z-index: 2; }

#circle .center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

#circle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + (139px * 2));
  height: calc(100% + (139px * 2));
  background: #F5F6F6;
  opacity: 0.5;
  border-radius: 50%;
  z-index: 1; }

#circle:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + (279px * 2));
  height: calc(100% + (279px * 2));
  background: #F5F6F6;
  opacity: 0.3;
  border-radius: 50%; }

#circle .dashed-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px dashed #E62A2A;
  width: calc(100% - 120px);
  height: calc(100% - 120px);
  border-radius: 50%;
  z-index: 3 !important; }

.lg {
  position: absolute;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  mix-blend-mode: normal;
  border-radius: 10px;
  z-index: 4 !important; }

.lg:nth-child(1) {
  top: 0;
  left: 50%;
  transform: translateX(-50%); }

.lg:nth-child(2) {
  top: 120px;
  left: calc(100% - 75px);
  transform: translateX(-100%); }

.lg:nth-child(3) {
  top: 50%;
  left: 100%;
  transform: translate(-100%, -50%); }

.lg:nth-child(4) {
  bottom: 120px;
  left: calc(100% - 75px);
  transform: translateX(-100%); }

.lg:nth-child(5) {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); }

.lg:nth-child(6) {
  bottom: 120px;
  left: 75px; }

.lg:nth-child(7) {
  top: 50%;
  left: 0;
  transform: translateY(-50%); }

.lg:nth-child(8) {
  top: 120px;
  left: 75px; }

@media (max-width: 1124px) {
  .about-company .wrapper {
    flex-direction: column; }
  .about-company-text-content {
    max-width: 100%;
    margin-right: 0; }
  #circle {
    --circleSize: 580px; }
  .lg {
    width: 100px;
    height: 100px; }
    .lg img {
      max-width: 80%; }
  #circle .dashed-circle {
    width: calc(100% - 100px);
    height: calc(100% - 100px); }
  .lg:nth-child(2) {
    top: 100px;
    left: calc(100% - 60px); }
  .lg:nth-child(4) {
    bottom: 100px;
    left: calc(100% - 60px); }
  .lg:nth-child(6) {
    bottom: 100px;
    left: 60px; }
  .lg:nth-child(8) {
    top: 100px;
    left: 60px; }
  .about-company {
    padding-top: 60px;
    padding-bottom: 40px; }
  #circle:before {
    width: calc(100% + (110px * 2));
    height: calc(100% + (110px * 2)); }
  #circle:after {
    width: calc(100% + (160px * 2));
    height: calc(100% + (160px * 2)); }
  #circle .center {
    width: 166px; } }

@media (max-width: 577px) {
  .about-company {
    padding-top: 40px;
    padding-bottom: 40px; }
  .about-company-text-content .title {
    margin-bottom: 20px; }
  .about-company-text-content .more-btn {
    margin-top: 20px; }
  #circle {
    --circleSize: 320px; }
  .lg {
    width: 68px;
    height: 68px; }
  .about-company-graph {
    margin-top: 40px; }
  .lg:nth-child(2) {
    top: 38px;
    left: calc(100% - 38px); }
  .lg:nth-child(8) {
    top: 38px;
    left: 38px; }
  .lg:nth-child(4) {
    bottom: 38px;
    left: calc(100% - 38px); }
  .lg:nth-child(6) {
    bottom: 38px;
    left: 38px; }
  #circle .dashed-circle {
    width: calc(100% - 68px);
    height: calc(100% - 68px); }
  #circle .center {
    width: 100px; }
  #circle:before {
    width: calc(100% + (60px * 2));
    height: calc(100% + (60px * 2)); }
  #circle:after {
    width: calc(100% + (120px * 2));
    height: calc(100% + (120px * 2)); } }

.news-section {
  padding-top: 80px;
  background: #F5F6F6; }
  .news-section .wrapper > .title {
    margin-bottom: 50px; }

.news-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px; }

.news-grid li:nth-child(1) {
  grid-area: 1 / 1 / 3 / 3; }

.news-grid li:nth-child(2) {
  grid-area: 1 / 3 / 2 / 4; }

.news-grid li:nth-child(3) {
  grid-area: 2 / 3 / 3 / 4; }

.news-tile {
  display: flex;
  flex-direction: column; }

.news-tile .news-tile-photo {
  height: 228px;
  position: relative; }
  .news-tile .news-tile-photo .lbl {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #1F1F1F;
    padding: 7px 10px 7px 20px;
    background: #F5F6F6; }
  .news-tile .news-tile-photo .lbl {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(50%); }
  .news-tile .news-tile-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.news-tile {
  background-color: #fff;
  height: 100%; }

.news-tile .news-tile-body {
  padding: 30px 20px 20px 20px; }
  .news-tile .news-tile-body .news-title {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 24px; }
  .news-tile .news-tile-body .exerpt {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden; }

.news-grid li:nth-child(1) .news-tile .news-tile-photo {
  height: 496px; }
  .news-grid li:nth-child(1) .news-tile .news-tile-photo .lbl {
    font-size: 14px;
    line-height: 20px;
    padding: 10px 20px 10px 30px; }

.news-grid li:nth-child(1) .news-tile .news-tile-body {
  padding: 50px 30px 30px 30px; }
  .news-grid li:nth-child(1) .news-tile .news-tile-body .news-title {
    font-size: 24px;
    line-height: 32px; }
  .news-grid li:nth-child(1) .news-tile .news-tile-body .exerpt {
    margin-bottom: 30px; }

.news-section .btn-in-bottom {
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto; }

@media (max-width: 1024px) {
  .news-section {
    padding-top: 60px; }
  .news-section .wrapper > .title {
    margin-bottom: 40px; }
  .news-tile .news-tile-photo {
    height: 178px; }
  .news-tile .news-tile-body .news-title {
    font-size: 16px;
    line-height: 22px; }
  .news-tile .news-tile-body .exerpt {
    font-size: 14px;
    line-height: 20px;
    line-clamp: 3;
    -webkit-line-clamp: 3; }
  .news-grid li:nth-child(1) .news-tile .news-tile-body {
    padding-top: 40px; }
  .news-grid li:nth-child(1) .news-tile .news-tile-photo {
    height: 474px; }
  .news-grid li:nth-child(1) .news-tile .news-tile-body .news-title {
    font-size: 20px;
    line-height: 28px; }
  .news-grid li:nth-child(1) .news-tile .news-tile-body .exerpt {
    font-size: 16px;
    line-height: 24px;
    line-clamp: 2;
    -webkit-line-clamp: 2; }
  .news-section .btn-in-bottom {
    margin-top: 60px; } }

@media (max-width: 768px) {
  .news-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px; }
  .news-grid li:nth-child(1) {
    width: 100%; }
  .news-grid li {
    width: 50%;
    padding: 10px; }
  .news-tile .news-tile-photo {
    height: 236px; }
  .news-grid li:nth-child(1) .news-tile .news-tile-body {
    padding-top: 40px; }
  .news-grid li:nth-child(1) .news-tile .news-tile-photo {
    height: 316px; }
  .news-grid li:nth-child(1) .news-tile .news-tile-body .news-title {
    font-size: 20px;
    line-height: 28px; }
  .news-grid li:nth-child(1) .news-tile .news-tile-body .exerpt {
    font-size: 16px;
    line-height: 24px;
    line-clamp: 2;
    -webkit-line-clamp: 2; }
  .news-section .btn-in-bottom {
    margin-top: 50px; } }

@media (max-width: 577px) {
  .news-section {
    padding-top: 40px; }
  .news-section .wrapper > .title {
    margin-bottom: 20px; }
  .news-grid li:nth-child(1) .news-tile .news-tile-photo, .news-tile .news-tile-photo {
    height: 236px; }
  .news-grid li:nth-child(1) .news-tile .news-tile-body, .news-tile .news-tile-body {
    padding: 30px 20px 20px 20px; }
  .news-grid li:nth-child(1) .news-tile .news-tile-body .news-title, .news-tile .news-tile-body .news-title {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px; }
  .news-grid li:nth-child(1) .news-tile .news-tile-body .exerpt, .news-tile .news-tile-body .exerpt {
    font-size: 14px;
    line-height: 20px;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    margin-bottom: 0; }
  .news-grid li:nth-child(1) .news-tile .news-tile-photo .lbl, .news-tile .news-tile-photo .lbl {
    padding: 7px 10px 7px 20px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em; }
  .news-grid li {
    width: 100%; }
  .news-grid li:nth-child(1) .news-tile .more-btn {
    display: none; }
  .news-section .btn-in-bottom {
    margin-top: 19px; } }

.features {
  padding-top: 80px;
  padding-bottom: 80px;
  background: #F5F6F6; }
  .features .title {
    margin-bottom: 50px; }

.feature-card {
  padding: 40px 30px;
  background-color: #fff; }
  .feature-card .ic {
    width: 52px;
    height: 52px;
    margin-bottom: 30px; }
  .feature-card strong {
    display: grid;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
    margin-bottom: 15px; }
  .feature-card p {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #1F1F1F; }

.features-list {
  display: grid !important; }

@media (max-width: 1024px) {
  .features {
    padding-top: 60px;
    padding-bottom: 60px; }
  .features .title {
    margin-bottom: 40px; }
  body .features-list.cards-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px; }
  .feature-card {
    padding: 30px 40px; }
  .feature-card strong {
    margin-bottom: 13px; }
  .feature-card p {
    font-size: 14px;
    line-height: 20px; } }

@media (min-width: 768px) {
  body .features-list.cards-grid {
    width: 100% !important; } }

@media (max-width: 768px) {
  body .features-list.cards-grid, .cards-grid {
    grid-column-gap: 20px;
    grid-row-gap: 20px; }
  .text-content-2-cols {
    column-count: 1; } }

@media (max-width: 577px) {
  .features {
    padding-top: 40px;
    padding-bottom: 42px; }
  .features-list {
    display: flex !important;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-columns: none !important; }
  .features-slider {
    height: 100%;
    margin-top: -10px; }
  body .features-slider {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px; }
  .features-slider.swiper-grid-column > .swiper-wrapper {
    flex-direction: row !important; }
  body .features-list.cards-grid, .cards-grid {
    grid-column-gap: 0;
    grid-row-gap: 0; }
  .feature-card.swiper-slide {
    margin-top: 10px !important;
    margin-bottom: 0 !important;
    height: auto; }
  .feature-card {
    padding: 30px; }
  .features .title {
    margin-bottom: 20px; }
  .feature-card .ic {
    margin-bottom: 18px; }
  .feature-card strong {
    margin-bottom: 9px; }
  body .features-slider {
    padding-bottom: 40px; }
    body .features-slider .swiper-pagination {
      bottom: 0 !important;
      left: 50%;
      transform: translateX(-50%) !important; } }

.categories {
  padding-top: 80px;
  padding-bottom: 40px; }
  .categories .wrapper > .title {
    margin-bottom: 48px; }

.categories-block-head {
  margin-bottom: 40px; }

.cat-link {
  display: flex;
  align-items: center; }
  .cat-link span {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #1F1F1F;
    margin-right: 20px; }
  .cat-link .ic {
    width: 54px;
    min-width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #E62A2A; }

.category-card {
  display: block;
  height: 100%; }
  .category-card .category-card-photo {
    height: 240px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F5F6F6; }
    .category-card .category-card-photo img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .category-card .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #1F1F1F; }

.categories-block {
  padding-top: 40px;
  padding-bottom: 58px;
  border-top: 2px solid rgba(31, 31, 31, 0.1); }

@media (max-width: 1024px) {
  .categories {
    padding-top: 60px;
    padding-bottom: 33px; }
  .categories .wrapper > .title {
    margin-bottom: 28px; }
  .cat-link span {
    font-size: 20px;
    line-height: 28px;
    margin-right: 20px; }
  .categories-block {
    padding-top: 30px;
    padding-bottom: 48px; }
  .categories-block-head {
    margin-bottom: 30px; }
  .cards-grid {
    grid-template-columns: repeat(3, 1fr); }
  .categories-grid li:last-child {
    display: none; } }

@media (max-width: 577px) {
  .categories {
    padding-top: 40px;
    padding-bottom: 24px; }
  .cat-link span {
    font-size: 18px;
    line-height: 24px; }
  .cat-link .ic {
    width: 48px;
    min-width: 48px;
    height: 48px; }
  .categories-block-head {
    margin-bottom: 21px; }
  .cards-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 30px; }
  .category-card .category-card-photo {
    height: 180px;
    margin-bottom: 10px; }
  .category-card .title {
    font-size: 14px;
    line-height: 20px; }
  .categories-block {
    padding-top: 20px;
    padding-bottom: 37px; }
  .categories-grid li:last-child {
    display: block; }
  .categories .wrapper > .title {
    margin-bottom: 16px; }
  .cat-link {
    justify-content: space-between; } }

.procedure {
  padding-top: 80px;
  background: #F5F6F6;
  overflow-x: hidden; }
  .procedure .wrapper > .title {
    margin-bottom: 50px; }
  .procedure .btn-in-bottom {
    margin-top: 80px; }

body .procedure-list {
  display: grid; }

.procedure-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 60px;
  max-width: 1260px; }

.procedure-item .num {
  font-weight: normal;
  font-size: 64px;
  line-height: 48px;
  color: #E62A2A;
  margin-bottom: 40px; }

.procedure-item strong {
  display: block;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #1F1F1F;
  margin-bottom: 15px; }

.procedure-item p {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1F1F1F; }

@media (max-width: 1024px) {
  .procedure {
    padding-top: 60px; }
  .procedure .wrapper > .title {
    margin-bottom: 40px; }
  .procedure-list {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 60px;
    grid-column-gap: 30px; }
  .procedure-item .num {
    font-size: 48px;
    line-height: 40px;
    margin-bottom: 20px; }
  .procedure-item strong {
    font-size: 16px;
    line-height: 22px; }
    .procedure-item strong br {
      display: none; }
  .procedure-item p {
    font-size: 14px;
    line-height: 20px; }
  .procedure .btn-in-bottom {
    margin-top: 60px; } }

.procedure-slider .swiper-pagination {
  display: none; }

@media (max-width: 577px) {
  .procedure {
    padding-top: 40px; }
  .procedure .wrapper > .title {
    margin-bottom: 30px; }
  body .procedure-list {
    display: flex;
    grid-template-columns: 100%;
    grid-row-gap: 0;
    grid-column-gap: 0; }
  .procedure-slider {
    padding-bottom: 46px !important;
    width: calc(100% + 40px); }
    .procedure-slider .swiper-pagination {
      bottom: 0 !important;
      left: 50%;
      transform: translateX(-50%);
      display: block; }
  body .procedure-slider {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px; }
  .procedure .btn-in-bottom {
    margin-top: 26px; } }

.service-centr {
  padding-top: 80px;
  padding-bottom: 40px; }
  .service-centr .wrapper > .title {
    margin-bottom: 30px; }

.text-content-2-cols {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px; }

@media (max-width: 1024px) {
  .service-centr {
    padding-top: 60px;
    padding-bottom: 30px; }
  .text-content .img-wrap {
    height: 458px; }
    .text-content .img-wrap img {
      height: 100%; }
  .text-content p + p {
    margin-top: 20px; } }

@media (max-width: 768px) {
  .text-content-2-cols {
    grid-template-columns: 100%; }
  .text-content .img-wrap {
    height: auto; }
  .text-content-2-cols .col:last-child {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px; }
    .text-content-2-cols .col:last-child .img-wrap {
      margin: 0; }
      .text-content-2-cols .col:last-child .img-wrap img {
        height: auto; }
  .text-content ul {
    margin-top: 30px;
    margin-bottom: 30px; } }

@media (max-width: 577px) {
  .service-centr {
    padding-top: 40px;
    padding-bottom: 30px; }
  .service-centr .wrapper > .title {
    margin-bottom: 20px; }
  .text-content-2-cols .col:last-child {
    display: flex;
    flex-direction: column; }
  .text-content-2-cols .col:last-child .img-wrap {
    order: 1;
    margin-bottom: 30px;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px; }
  .text-content-2-cols .col:last-child p {
    order: 2; }
  .text-content-2-cols .col:last-child .img-wrap img {
    height: 240px; } }

.reviews {
  padding-top: 40px;
  padding-bottom: 100px;
  overflow-x: hidden; }
  .reviews .wrapper > .title {
    margin-bottom: 80px; }

.review-card {
  padding: 30px;
  background: #F5F6F6;
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-column-gap: 40px; }
  .review-card .rc-content {
    padding-top: 35px; }
    .review-card .rc-content .title {
      display: block;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      color: #1F1F1F;
      margin-bottom: 30px; }
    .review-card .rc-content p {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #1F1F1F; }
      .review-card .rc-content p + p {
        margin-top: 21px; }

.reviews-slider {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  overflow: visible !important;
  position: relative; }
  .reviews-slider .swiper-slide .review-card {
    transform: scale(0.75);
    transition: transform .3s ease-in;
    will-change: transform; }
  .reviews-slider .swiper-slide-active .review-card {
    transform: scale(1); }
  .reviews-slider .swiper-slide-prev .review-card {
    transform-origin: right; }
  .reviews-slider .swiper-slide-next .review-card {
    transform-origin: left; }

.sld-nav-btn {
  position: absolute;
  top: 43%;
  transform: translateY(-50%);
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: #E62A2A;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3; }
  .sld-nav-btn-prev {
    left: 0;
    transform: translateX(-50%); }
  .sld-nav-btn-next {
    right: 0;
    transform: translateX(50%); }

.reviews-slider .swiper-pagination {
  display: none; }

@media (max-width: 1024px) {
  .reviews {
    padding-top: 30px;
    padding-bottom: 60px; }
  .reviews .wrapper > .title {
    margin-bottom: 40px; }
  .reviews-slider {
    max-width: 592px; }
  .review-card .rc-content .title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 30px; }
  .review-card .rc-content p {
    font-size: 14px;
    line-height: 20px; }
  .review-card .rc-content p + p {
    margin-top: 10px; }
  .review-card {
    padding: 20px;
    grid-template-columns: 260px 1fr;
    grid-column-gap: 30px; }
  .review-card .rc-content {
    padding-top: 31px; }
  .reviews-slider .swiper-slide:not(.swiper-slide-active) .review-card {
    transform: scale(0.632); }
  .sld-nav-btn-prev {
    left: -22%; }
  .sld-nav-btn-next {
    right: -22%; } }

@media (max-width: 768px) {
  .reviews .wrapper > .title {
    margin-bottom: 30px; }
  .sld-nav-btn-prev {
    left: -21px; }
  .sld-nav-btn-next {
    right: -21px; } }

@media (max-width: 577px) {
  body .reviews-slider {
    max-width: none;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px; }
  .reviews-slider .swiper-slide .review-card {
    padding: 20px 20px 30px;
    display: block; }
  .reviews-slider .sld-nav-btn {
    display: none; }
  .rc-image {
    height: 200px;
    width: 100%;
    background-color: #fff; }
    .rc-image img {
      height: 100%;
      width: 100%;
      object-fit: scale-down; }
  .review-card .rc-content {
    padding-top: 30px; }
  .review-card .rc-content .title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px; }
  .reviews .wrapper > .title {
    margin-bottom: 20px; }
  body .reviews-slider {
    padding-bottom: 43px; }
  .reviews-slider .swiper-pagination {
    display: block;
    bottom: 0 !important;
    left: 50%;
    transform: translateX(-50%); }
    .reviews-slider .swiper-pagination .swiper-pagination-bullet {
      background-color: #F5F6F6; }
  .reviews-slider .swiper-slide .review-card {
    transform: scale(1) !important; }
  .reviews {
    padding-bottom: 38px; } }

.about-us {
  background-image: url("../img/bank-phrom-Tzm3Oyu_6sk-unsplash.jpg");
  background-position: top 0 right 0;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px; }
  .about-us .wrapper {
    display: flex;
    background-image: url("../img/svg/about-us-shape.svg");
    background-position: top 0 right 15px;
    background-repeat: no-repeat;
    padding-bottom: 102px; }
  .about-us .title {
    margin-bottom: 60px; }
  .about-us p {
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: #1F1F1F; }
    .about-us p + p {
      margin-top: 20px; }
    .about-us p strong {
      font-weight: 700; }
  .about-us .p-xs {
    font-size: 16px;
    line-height: 24px; }

.about-us-content {
  max-width: 740px;
  width: 100%;
  margin-right: 30px; }
  .about-us-content .more-btn {
    margin-top: 30px; }

.cnt-lst {
  margin-top: 80px;
  margin-bottom: 80px;
  display: flex; }
  .cnt-lst li {
    max-width: 155px; }
  .cnt-lst li:not(:last-child) {
    margin-right: 100px; }
  .cnt-lst li strong {
    display: block;
    font-size: 64px;
    line-height: 48px;
    color: #E62A2A;
    margin-bottom: 30px; }
  .cnt-lst li span {
    display: block;
    font-size: 18px;
    line-height: 26px;
    color: #1F1F1F; }

.about-us-col {
  padding-top: 172px; }

@media (max-width: 1024px) {
  .about-us {
    padding-top: 60px; }
    .about-us .wrapper {
      padding-bottom: 60px; }
  .about-us .title {
    margin-bottom: 40px; }
  .about-us-content {
    max-width: 593px; }
  .cnt-lst {
    margin-top: 60px;
    margin-bottom: 60px; }
  .cnt-lst li span {
    font-size: 16px;
    line-height: 24px; }
  .about-us-col .logo-big {
    width: 167px; }
  .about-us-col {
    padding-top: 190px;
    transform: translateX(-71px); }
  .about-us .wrapper {
    background-size: 437px;
    background-position: top 86px right 60px; } }

@media (max-width: 768px) {
  .about-us-content {
    max-width: 511px;
    margin-right: 11px; }
  .about-us-col {
    padding-top: 41.5%;
    transform: none; }
  .about-us .wrapper {
    background-size: 511px;
    background-position: top 0 right 40px; } }

@media (max-width: 577px) {
  .about-us {
    padding-top: 30px; }
    .about-us .wrapper {
      padding-bottom: 30px; }
  .about-us .wrapper {
    background-size: 320px;
    background-position: top 0 right -73px; }
  .about-us .title {
    margin-bottom: 30px; }
  .about-us p {
    font-size: 16px;
    line-height: 24px; }
  .cnt-lst li:not(:last-child) {
    margin-right: 10px; }
  .cnt-lst li strong {
    font-size: 48px;
    line-height: 40px;
    margin-bottom: 20px; }
  .cnt-lst li span {
    font-size: 14px;
    line-height: 20px; }
  .about-us-col {
    display: none; }
  .cnt-lst {
    margin-top: 30px;
    margin-bottom: 30px; }
  .about-us-content {
    margin-right: 0; }
    .about-us-content .more-btn .ic {
      width: 48px;
      min-width: 48px;
      height: 48px; }
  .about-us .p-xs {
    font-size: 14px;
    line-height: 20px; } }

.logos-section {
  padding-top: 60px;
  padding-bottom: 60px; }

.logos-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 5.4%;
  grid-row-gap: 30px; }
  .logos-grid li img {
    width: 100%; }

@media (max-width: 1024px) {
  .logos-grid {
    grid-column-gap: 4%; } }

@media (max-width: 768px) {
  .logos-section {
    padding-top: 40px;
    padding-bottom: 40px; }
  .logos-grid {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 60px;
    padding-left: 54px;
    padding-right: 54px; } }

@media (max-width: 577px) {
  .logos-section {
    padding-top: 30px;
    padding-bottom: 30px; }
  .logos-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 80px;
    grid-row-gap: 30px;
    padding-left: 40px;
    padding-right: 40px; } }

.news-page {
  padding-top: 80px;
  padding-bottom: 100px; }
  .news-page .title {
    margin-bottom: 60px; }

.news-tiles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 60px; }

.news-grid + .news-tiles {
  margin-top: 60px; }

@media (max-width: 1024px) {
  .news-page {
    padding-top: 60px;
    padding-bottom: 80px; }
  .news-page .title {
    margin-bottom: 50px; }
  .news-grid + .news-tiles {
    margin-top: 40px; }
  .news-tiles {
    grid-row-gap: 40px; } }

@media (max-width: 768px) {
  .news-tiles {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 40px; }
  .news-page .news-grid li:nth-child(1) {
    width: 100%;
    margin-bottom: 20px; }
  .news-grid + .news-tiles {
    margin-top: 30px; } }

@media (max-width: 577px) {
  .news-page {
    padding-top: 28px;
    padding-bottom: 60px; }
  .news-page .title {
    margin-bottom: 30px; }
  .news-tiles {
    grid-template-columns: 100%;
    grid-row-gap: 20px; }
  .news-page .news-grid li:nth-child(1) {
    margin-bottom: 0; } }

.page-with-bradcrumbs {
  padding-top: 60px;
  padding-bottom: 100px; }

.bredcrumbs {
  margin-bottom: 40px; }
  .bredcrumbs a {
    position: relative; }
    .bredcrumbs a .dot {
      width: 4px;
      height: 4px;
      background-color: #E62A2A;
      border-radius: 50%;
      display: inline-block;
      margin-left: 20px;
      margin-right: 20px;
      transform: translateY(-3px); }
  .bredcrumbs a {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #1F1F1F; }
  .bredcrumbs .ellipsis {
    margin-right: 20px;
    transform: translateY(-3px);
    display: none;
    pointer-events: none;
    min-width: 22px; }
  .bredcrumbs.collapsed .ellipsis {
    display: inline-block !important; }
  .bredcrumbs.collapsed > *:not(:nth-last-child(-n+2)) {
    display: none; }

.news-detail-page .page-title {
  margin-bottom: 60px; }

.detail-grid {
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-column-gap: 60px; }

.detail-grid-inner {
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-column-gap: 60px; }

.left-sidebar {
  padding-right: 10px; }
  .left-sidebar .socials-row {
    position: sticky;
    top: 20px; }

.read-more-col {
  position: sticky;
  top: 20px; }

.read-more-col .title {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #1F1F1F;
  margin-bottom: 20px; }

.read-more-col ul li a {
  display: block;
  padding: 20px 0 19px 0;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #1F1F1F;
  border-top: 1px solid rgba(31, 31, 31, 0.1); }

.date-box .date {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #1F1F1F;
  transform: rotate(-90deg);
  white-space: nowrap; }

.date-box {
  min-height: 164px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  margin-bottom: 60px; }

.artcl-content {
  width: 100%; }
  .artcl-content > img {
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
  .artcl-content .main-thumbnail {
    margin-bottom: 40px; }
    .artcl-content .main-thumbnail img {
      max-width: none;
      width: 100%; }
  .artcl-content p {
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    color: #1F1F1F; }
    .artcl-content p + p {
      margin-top: 20px; }
    .artcl-content p a {
      color: #E62A2A; }
  .artcl-content h3 {
    margin-top: 60px;
    margin-bottom: 20px; }
  .artcl-content ul {
    margin-top: 20px;
    margin-bottom: 20px; }
    .artcl-content ul li:not(:last-child) {
      margin-bottom: 21px; }
    .artcl-content ul li {
      position: relative;
      font-weight: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #1F1F1F;
      padding-left: 36px; }
      .artcl-content ul li::before {
        content: "";
        position: absolute;
        top: 9px;
        left: 5px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #E62A2A; }
  .artcl-content .gallery {
    margin-top: 40px;
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px; }
  .artcl-content blockquote {
    display: block;
    position: relative;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 92px 60px 40px 60px;
    background: #FFFFFF;
    text-align: center; }
    .artcl-content blockquote:before {
      content: "";
      position: absolute;
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
      width: 32px;
      height: 32px;
      background-image: url("../img/svg/quotes.svg");
      background-position: center center;
      background-repeat: no-repeat; }
    .artcl-content blockquote > p {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #1F1F1F;
      margin-bottom: 20px; }
    .artcl-content blockquote span {
      display: block;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      color: #E62A2A; }

.sidebar .art-socials-col {
  display: none; }

@media (max-width: 1024px) {
  .page-with-bradcrumbs {
    padding-top: 40px;
    padding-bottom: 80px; }
  .detail-grid {
    display: flex;
    flex-direction: column;
    grid-column-gap: 0; }
  .left-sidebar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    padding-right: 0; }
  .date-box {
    width: 164px;
    min-width: 164px;
    height: 40px;
    min-height: auto;
    margin-bottom: 0; }
    .date-box .date {
      transform: none; }
  .socials-row--vertical {
    display: flex; }
    .socials-row--vertical li:not(:last-child) {
      margin-bottom: 0;
      margin-right: 20px; }
  .detail-grid-inner {
    grid-template-columns: 1fr 204px;
    grid-column-gap: 30px; }
  .news-detail-page .page-title {
    margin-bottom: 30px; }
  .bredcrumbs {
    margin-bottom: 30px; }
  .read-more-col .title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 13px; }
  .read-more-col ul li a {
    font-size: 16px;
    line-height: 24px;
    padding-top: 15px;
    padding-bottom: 14px; }
  .artcl-content p {
    font-size: 18px;
    line-height: 28px; }
  .artcl-content h3 {
    font-size: 20px;
    line-height: 28px;
    margin-top: 40px; }
  .artcl-content ul li {
    padding-left: 32px;
    font-size: 16px;
    line-height: 22px; }
  .artcl-content blockquote {
    padding: 84px 40px 40px 40px;
    margin-bottom: 30px; }
  .artcl-content blockquote:before {
    top: 32px; }
  .artcl-content blockquote span {
    font-size: 16px;
    line-height: 22px; } }

@media (max-width: 768px) {
  .page-with-bradcrumbs {
    padding-bottom: 68px; }
  .left-sidebar .socials-row {
    margin-right: 3px; }
  .detail-grid-inner {
    display: flex;
    flex-direction: column;
    grid-column-gap: 0; }
  .artcl-content .gallery {
    grid-column-gap: 20px;
    grid-row-gap: 20px; }
  .artcl-content blockquote {
    padding-bottom: 30px;
    margin-bottom: 40px; }
  .sidebar {
    margin-top: 60px; } }

@media (max-width: 577px) {
  .left-sidebar .socials-row {
    display: none; }
  .page-with-bradcrumbs {
    padding-top: 18px;
    padding-bottom: 60px; }
  .bredcrumbs li {
    padding-right: 28px; }
  .bredcrumbs li:after {
    right: 12px; }
  .bredcrumbs li a {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em; }
  .bredcrumbs {
    margin-bottom: 20px; }
  .news-detail-page .page-title {
    margin-bottom: 20px; }
  .date-box {
    width: auto;
    min-width: 139px;
    height: 32px;
    margin-left: -20px;
    padding-left: 20px;
    padding-right: 12px; }
    .date-box .date {
      font-size: 12px;
      line-height: 16px; }
  .left-sidebar {
    margin-bottom: 20px; }
  .artcl-content .main-thumbnail {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px; }
    .artcl-content .main-thumbnail img {
      height: 240px; }
  .artcl-content .main-thumbnail {
    margin-bottom: 20px; }
  .artcl-content p {
    font-size: 16px;
    line-height: 24px; }
  .artcl-content p + p {
    margin-top: 15px; }
  .artcl-content h3 {
    font-size: 18px;
    line-height: 24px;
    margin-top: 30px; }
  .artcl-content ul li {
    font-size: 14px;
    line-height: 20px; }
  .artcl-content ul li:not(:last-child) {
    margin-bottom: 15px; }
  .artcl-content .gallery {
    margin-top: 30px;
    margin-bottom: 30px;
    grid-template-columns: 100%;
    grid-row-gap: 20px; }
  .artcl-content blockquote {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 67px 20px 20px 20px; }
    .artcl-content blockquote span {
      font-size: 16px;
      line-height: 22px; }
    .artcl-content blockquote > p {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 15px; }
    .artcl-content blockquote:before {
      top: 20px; }
  .sidebar .art-socials-col {
    display: block;
    padding: 30px 20px;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 30px;
    border-top: 1px solid rgba(31, 31, 31, 0.1);
    border-bottom: 1px solid rgba(31, 31, 31, 0.1); }
    .sidebar .art-socials-col .title {
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      color: #1F1F1F;
      margin-bottom: 20px; }
    .sidebar .art-socials-col .socials-row {
      display: flex; }
      .sidebar .art-socials-col .socials-row li:not(:last-child) {
        margin-right: 16px; }
  .sidebar {
    margin-top: 30px; }
  .read-more-col .title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px; }
  .page-with-bradcrumbs {
    padding-bottom: 45px; }
  .bredcrumbs a {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    margin-bottom: 12px; }
  .bredcrumbs a .dot {
    margin-left: 12px;
    margin-right: 12px; }
  .bredcrumbs .ellipsis {
    margin-right: 12px; } }

.catalog-page .page-title {
  margin-bottom: 60px; }

.catalog-page {
  padding-top: 80px;
  padding-bottom: 100px; }

.catalog-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 60px; }

.catalog-card-item {
  position: relative; }
  .catalog-card-item:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0, #F5F6F6 46.88%, rgba(245, 246, 246, 0) 100%); }
  .catalog-card-item > *:not(.catalog-card-item-photo) {
    position: relative;
    z-index: 1; }
  .catalog-card-item .catalog-card-item-photo {
    height: 420px; }
    .catalog-card-item .catalog-card-item-photo img {
      width: 100%;
      object-fit: contain; }
  .catalog-card-item .catalog-card-item-body {
    margin-top: -40px; }
  .catalog-card-item .cat-link {
    margin-bottom: 40px;
    justify-content: space-between; }
    .catalog-card-item .cat-link span {
      font-size: 24px;
      line-height: 32px; }
  .catalog-card-item .catalog-card-item-body {
    padding: 0 40px 40px 40px; }
  .catalog-card-item .catalog-card-item-cols {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px; }
  .catalog-card-item .catalog-card-item-col .cat-level-2 {
    display: block;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #1F1F1F; }
  .catalog-card-item .catalog-card-item-col > .cat-level-2 {
    margin-bottom: 30px; }
  .catalog-card-item .catalog-card-item-col ul li:not(:last-child) {
    margin-bottom: 15px; }
  .catalog-card-item .catalog-card-item-col ul li a {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #1F1F1F; }

.callback-section--mg {
  margin-top: 80px;
  margin-bottom: 80px; }

.personal-selection .form-inputs {
  margin-bottom: 60px; }

.catalog-card-item-photo img {
  width: 100%;
  height: 100%;
  object-fit: contain; }

@media (max-width: 1260px) {
  .catalog-card-item .catalog-card-item-col .cat-level-2 {
    font-size: 20px;
    line-height: 28px; }
  .catalog-card-item .catalog-card-item-col ul li a {
    font-size: 18px;
    line-height: 24px; } }

@media (max-width: 1220px) {
  .catalog-card-item .catalog-card-item-cols {
    grid-template-columns: 0;
    grid-row-gap: 0;
    display: flex;
    flex-direction: column; }
    .catalog-card-item .catalog-card-item-cols .catalog-card-item-col:not(:last-child) {
      margin-bottom: 20px;
      height: fit-content; } }

@media (max-width: 1024px) {
  .catalog-page {
    padding-top: 60px;
    padding-bottom: 80px; }
  .catalog-page .page-title {
    margin-bottom: 50px; }
  .catalog-card-item .catalog-card-item-photo {
    height: 290px; }
  .catalog-card-item .catalog-card-item-body {
    padding: 0 30px 30px 30px; }
  .catalog-card-item .cat-link {
    margin-bottom: 20px; }
  .catalog-card-item .cat-link span {
    font-size: 20px;
    line-height: 28px; }
  .catalog-card-item .catalog-card-item-body {
    margin-top: -20px; }
  .catalog-card-item .catalog-card-item-col .cat-level-2 {
    font-size: 18px;
    line-height: 24px; }
  .catalog-card-item .catalog-card-item-col > .cat-level-2 {
    margin-bottom: 14px; }
  .catalog-card-item .catalog-card-item-col ul li a {
    font-size: 14px;
    line-height: 20px; }
  .catalog-card-item .catalog-card-item-col ul li:not(:last-child) {
    margin-bottom: 10px; }
  .callback-section--mg {
    margin-top: 40px;
    margin-bottom: 40px; }
  .personal-selection .form-inputs {
    margin-bottom: 40px; }
  .personal-selection:not(.callback-section--t2) .wrapper .callback-section-title-col p {
    max-width: 380px; }
  .catalog-card-item .catalog-card-item-col ul li:not(:last-child) .cat-level-2 {
    margin-bottom: 20px; }
  .catalog-grid {
    grid-column-gap: 30px;
    grid-row-gap: 40px; }
  .catalog-card-item .cat-link span {
    font-weight: bold; } }

@media (max-width: 768px) {
  .catalog-grid {
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 20px; }
  .callback-section--mg {
    margin-top: 60px;
    margin-bottom: 40px; }
  .personal-selection:not(.callback-section--t2) .wrapper .callback-section-title-col p {
    max-width: 100%; }
  .personal-selection:not(.callback-section--t2) .wrapper {
    grid-template-columns: 100%;
    grid-row-gap: 40px; } }

@media (min-width: 577px) and (max-width: 768px) {
  .catalog-card-item {
    display: flex; }
    .catalog-card-item .catalog-card-item-photo {
      order: 2; }
  .catalog-card-item:after {
    background: linear-gradient(90deg, #F5F6F6 46.88%, rgba(245, 246, 246, 0) 100%); }
  .catalog-card-item .catalog-card-item-photo {
    height: 220px;
    width: 48.55%;
    align-self: flex-end;
    position: relative;
    position: absolute;
    bottom: 0;
    right: 0; }
  .catalog-card-item {
    min-height: 220px; }
  .catalog-card-item .catalog-card-item-body {
    margin-top: 0;
    padding: 30px 0 40px 40px;
    width: calc(100% + 54px); }
  .catalog-card-item .cat-link {
    justify-content: flex-start; } }

@media (max-width: 577px) {
  .catalog-page {
    padding-top: 28px;
    padding-bottom: 60px; }
  .catalog-page .page-title {
    margin-bottom: 30px; }
  .catalog-grid {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px; }
  .catalog-card-item .catalog-card-item-photo {
    height: 212px;
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
  .catalog-card-item .catalog-card-item-body {
    margin-top: -10px; }
  .catalog-card-item .catalog-card-item-body {
    padding: 0 20px 36px 20px; }
  .catalog-card-item .cat-link span {
    font-size: 18px;
    line-height: 24px;
    margin-right: 10px; }
  .catalog-card-item .catalog-card-item-col .cat-level-2 {
    font-size: 16px;
    line-height: 22px; }
  .callback-section--mg {
    margin-top: 40px;
    margin-bottom: 40px; }
  .personal-selection .form-inputs {
    margin-bottom: 30px; }
  .catalog-card-item .catalog-card-item-col ul li:not(:last-child) .cat-level-2 {
    margin-bottom: 10px; }
  .catalog-card-item .catalog-card-item-cols--no-mb-mob .catalog-card-item-col:not(:last-child) {
    margin-bottom: 10px; }
  .catalog-card-item .catalog-card-item-photo--fw-mb img {
    object-fit: cover; }
  .catalog-card-item {
    min-height: 280px; } }

.catalog-list-page .page-title {
  margin-bottom: 60px; }

.product-list li:first-child .product-card {
  padding-top: 0; }

.product-list li + li .product-card {
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

.catalog-list-page .pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 30px; }

@media (max-width: 1160px) {
  .has-sidebar {
    grid-template-columns: 203px 1fr; } }

@media (max-width: 1024px) {
  .catalog-list-page .page-title {
    margin-bottom: 50px; }
  .catalog-list-page .pagination {
    margin-top: 10px; } }

@media (max-width: 940px) {
  .has-sidebar {
    display: flex;
    flex-direction: column; }
    .has-sidebar .aside {
      order: 2;
      margin-top: 60px; } }

@media (max-width: 768px) {
  .filter-block-title + * {
    margin-top: 10px; }
  .filter-box ul.level-2 {
    margin-top: 10px; }
  .filter-box ul:not(.checks-list) li:not(:last-child) {
    margin-bottom: 10px; }
  .filter-box + .filter-box {
    margin-top: 25px; }
  .checks-block--accent {
    margin-bottom: 30px; }
  .filter {
    padding-bottom: 28px; }
  .catalog-list-page .page-title {
    margin-bottom: 30px; }
  .catalog-list-page .pagination {
    margin-top: 20px; }
  .has-sidebar .aside {
    margin-top: 40px; } }

.product-card {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-column-gap: 30px;
  padding-top: 30px;
  padding-bottom: 29px; }

.product-card-photo img {
  width: 100%;
  height: 100%;
  object-fit: contain; }

.product-card-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%; }
  .product-card-body .product-title {
    margin-bottom: 10px; }
  .product-card-body .product-cats {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #1F1F1F;
    margin-bottom: 30px; }
  .product-card-body .product-card-body-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto; }
  .product-card-body .characteristics {
    margin-bottom: 30px; }

.characteristics li:not(:last-child) {
  margin-bottom: 10px; }

.characteristics li {
  display: flex;
  flex-wrap: wrap; }
  .characteristics li span {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #AEAEAE;
    margin-right: 10px; }
  .characteristics li strong {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F; }

.characteristics--smaller li:not(:last-child) {
  margin-bottom: 10px; }

.characteristics--smaller li span {
  font-size: 14px;
  line-height: 20px;
  margin-right: 8px; }

.characteristics--smaller li strong {
  font-size: 14px;
  line-height: 20px; }

.product-card-title-mob {
  display: none; }

@media (max-width: 1024px) {
  .product-card {
    grid-template-columns: 204px 1fr;
    grid-column-gap: 20px; }
    .product-card .product-card-photo {
      height: 204px; }
  .product-card-body .product-title {
    font-size: 20px;
    line-height: 28px; }
  .product-card-body .product-cats {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px; }
  .characteristics li span {
    font-size: 14px;
    line-height: 20px; }
  .characteristics li strong {
    font-size: 14px;
    line-height: 20px; }
  .characteristics li:not(:last-child) {
    margin-bottom: 6px; }
  .product-card-body .characteristics {
    margin-bottom: 20px; } }

@media (max-width: 768px) {
  .product-card {
    grid-template-columns: 100%;
    grid-row-gap: 10px; }
  .product-card .product-card-photo {
    height: 120px; }
  .product-card-photo-container {
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-column-gap: 12px; }
  .product-card-title-mob {
    display: block; }
    .product-card-title-mob .product-title {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 10px; }
    .product-card-title-mob .product-cats {
      font-size: 14px;
      line-height: 18px; }
  .product-card-body .product-title, .product-card-body .product-cats {
    display: none; }
  .product-card-body-footer .accent-btn {
    display: none; }
  .product-card-body .product-card-body-footer {
    justify-content: flex-end; }
  .characteristics li span {
    font-size: 12px;
    line-height: 16px;
    margin-right: 6px;
    margin-top: 6px; }
  .characteristics li strong {
    font-size: 12px;
    line-height: 16px;
    margin-top: 6px; }
  .characteristics li:not(:last-child) {
    margin-bottom: 0; }
  .product-card {
    grid-row-gap: 5px; }
    .product-card .more-btn .ic {
      width: 48px;
      min-width: 48px;
      height: 48px; }
  .product-card-body .characteristics {
    margin-bottom: 9px; }
  .product-card {
    padding-top: 20px;
    padding-bottom: 19px; } }

.catalog-product-page {
  padding-bottom: 60px; }
  .catalog-product-page .page-title {
    margin-bottom: 60px; }

.product-detail-card {
  display: flex;
  justify-content: space-between; }
  .product-detail-card .product-detail-photos, .product-detail-card .product-detail-body {
    width: calc(50% - 15px);
    min-width: calc(50% - 15px); }

.swiper-thumbs {
  margin-top: 10px;
  width: calc(100% + 18px); }

.swiper-thumbs .swiper-slide {
  border: 1px solid transparent; }
  .swiper-thumbs .swiper-slide-thumb-active {
    border-color: #E62A2A; }

.swiper-gallery .swiper-slide {
  height: auto; }

.swiper-gallery .swiper-slide iframe {
  width: 100%;
  height: 100%; }

.swiper-gallery .swiper-slide img, .swiper-thumbs .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: contain; }

.video-thumb {
  position: relative; }
  .video-thumb:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    background-image: url("../img/svg/video-btn.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain; }

.product-detail-actions {
  display: flex; }
  .product-detail-actions .action + .action {
    margin-left: 20px; }

.product-detail-body > .title {
  margin-bottom: 30px; }

.product-detail-body .product-detail-actions {
  margin-top: 40px; }

.product-detai-socials {
  margin-top: 60px;
  display: flex;
  justify-content: space-between; }

.socials-box {
  padding-top: 10px; }

.socials-box strong {
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1F1F1F;
  margin-bottom: 15px; }

.socials-row:not(.socials-row--vertical) {
  display: flex; }
  .socials-row:not(.socials-row--vertical) li:not(:last-child) {
    margin-right: 10px; }

.product-detail-decription {
  padding-top: 60px;
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px; }
  .product-detail-decription--single-col {
    grid-template-columns: 100%;
    grid-row-gap: 30px; }
  .product-detail-decription .title {
    margin-bottom: 30px; }
  .product-detail-decription p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F; }
    .product-detail-decription p + p {
      margin-top: 20px; }

.product-detail-photos .socials-row {
  display: none; }

.socials-box--mob {
  display: none; }

.product-detail-body-inner > .title {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 30px; }

@media (max-width: 1024px) {
  .catalog-product-page {
    padding-bottom: 40px; }
  .product-detail-actions .accent-btn {
    padding-left: 30px;
    padding-right: 30px; }
  .product-detail-decription {
    grid-template-columns: 100%;
    grid-row-gap: 40px; }
  .catalog-product-page .page-title {
    margin-bottom: 50px; }
  .product-detail-body > .title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px; }
  .product-detail-body .characteristics li:not(:last-child) {
    margin-bottom: 10px; }
  .product-detail-body .product-detail-actions {
    margin-top: 28px; }
  .socials-box strong {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 15px; }
  .product-detai-socials {
    margin-top: 30px; }
  .manufacturer {
    width: 80px;
    height: 80px;
    margin-top: 10px; }
    .manufacturer img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .swiper-thumbs {
    width: 100%; }
  .product-detail-decription {
    padding-top: 40px; }
  .product-detail-decription .title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px; }
  .product-detail-decription p {
    font-size: 16px;
    line-height: 24px; }
  .product-detail-body-inner > .title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px; } }

@media (min-width: calc($mb + 1px)) and (max-width: 1000px) {
  .product-detail-body .product-detail-actions {
    flex-direction: column; }
    .product-detail-body .product-detail-actions .accent-btn {
      width: 100%; }
  .product-detail-actions .action + .action {
    margin-left: 0;
    margin-top: 20px; } }

@media (min-width: 577px) and (max-width: 768px) {
  .swiper-thumbs .swiper-slide {
    height: 80px; }
  .swiper-thumbs {
    width: 80px;
    min-width: 80px;
    margin-right: 10px !important;
    margin-top: 0 !important; }
  .swiper-gallery {
    flex: 1; }
  .product-detail-photos .socials-row {
    display: block;
    order: 2;
    margin-left: 48px; }
  .product-detail-card .socials-row--vertical li:not(:last-child) {
    margin-bottom: 10px;
    margin-right: 0; } }

@media (max-width: 768px) {
  .product-detail-card {
    flex-direction: column; }
  .product-detail-card .product-detail-photos, .product-detail-card .product-detail-body {
    width: 100%; }
  .product-detail-card .product-detail-photos {
    margin-bottom: 40px; }
  .product-detail-photos {
    display: flex; }
    .product-detail-photos .swiper-gallery {
      order: 1; }
  .product-detai-socials .socials-box {
    display: none; }
  .product-detail-body {
    display: grid;
    grid-template-columns: 1fr 80px;
    grid-column-gap: 30px; }
  .manufacturer {
    margin-top: 0; }
  .product-detail-body-inner > .title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px; }
  .product-detai-socials {
    margin-top: 0; }
  .product-detail-body .characteristics li span {
    font-size: 14px;
    line-height: 20px;
    margin-right: 10px;
    margin-top: 0; }
  .product-detail-body .characteristics li strong {
    font-size: 14px;
    line-height: 20px;
    margin-top: 0; } }

@media (max-width: 577px) {
  .product-detail-photos {
    flex-direction: column; }
  .product-detail-photos .swiper-gallery {
    order: 1;
    width: 100%;
    max-width: 100%; }
  .swiper-thumbs {
    order: 2; }
  .manufacturer {
    display: none; }
  .product-detail-body {
    grid-template-columns: 100%; }
  .socials-box--mob {
    display: block;
    padding-top: 30px;
    padding-bottom: 20px;
    border-top: 1px solid rgba(31, 31, 31, 0.1);
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px; }
  .catalog-product-page .page-title {
    margin-bottom: 30px; }
  .swiper-thumbs {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px; }
  .swiper-thumbs .swiper-slide {
    width: 60px;
    height: 60px; }
  .product-detail-card .product-detail-photos {
    margin-bottom: 30px; }
  .product-detail-body-inner > .title {
    font-size: 18px;
    line-height: 24px; }
  .product-detail-body .characteristics li:not(:last-child) {
    margin-bottom: 0; }
  .product-detail-body .characteristics li:not(:first-child) span, .product-detail-body .characteristics li:not(:first-child) strong {
    margin-top: 6px; }
  .product-detail-body .product-detail-actions {
    flex-direction: column; }
    .product-detail-body .product-detail-actions .accent-btn {
      width: 100%; }
  .product-detail-actions .action + .action {
    margin-left: 0;
    margin-top: 20px; }
  .product-detail-actions .accent-btn {
    padding: 13px 30px; }
  .product-detail-decription .title {
    font-size: 18px;
    line-height: 24px; }
  .product-detail-decription {
    padding-top: 30px; }
  .product-detail-decription p {
    font-size: 14px;
    line-height: 20px; }
  .product-detail-decription p + p {
    margin-top: 10px; }
  .product-detail-decription {
    grid-row-gap: 30px; }
  .product-detail-decription {
    padding-bottom: 30px; }
  .socials-box strong {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px; }
  .socials-box--mob .socials-row:not(.socials-row--vertical) {
    justify-content: space-between; }
  .socials-box--mob .socials-row:not(.socials-row--vertical) li:not(:last-child) {
    margin-right: 0; } }

.service-main {
  padding-top: 80px;
  padding-bottom: 80px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.intro-row {
  display: grid;
  grid-template-columns: 1fr 410px;
  grid-column-gap: 30px;
  margin-bottom: 80px; }
  .intro-row .text {
    max-width: 630px; }
    .intro-row .text .title {
      font-size: 44px;
      line-height: 54px;
      color: #FFFFFF;
      margin-bottom: 60px; }
    .intro-row .text p {
      font-size: 18px;
      line-height: 26px;
      color: #FFFFFF; }
      .intro-row .text p + P {
        margin-top: 20px; }
  .intro-row .get-consult {
    margin-top: -80px; }

.jobs-types > .title {
  color: #FFFFFF;
  margin-bottom: 60px; }

.jobs-type-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px; }
  .jobs-type-list .ic {
    margin-bottom: 20px;
    width: 48px;
    height: 48px; }
    .jobs-type-list .ic img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .jobs-type-list strong {
    display: block;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
    color: #FFFFFF; }
  .jobs-type-list p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF; }

.steps-section {
  padding-top: 80px;
  padding-bottom: 80px; }

.steps-list li:not(:last-child) {
  margin-bottom: 60px; }

.step-row {
  display: grid;
  grid-template-columns: 190px 1fr;
  grid-column-gap: 30px; }
  .step-row .num {
    font-weight: normal;
    font-size: 80px;
    line-height: 64px;
    color: #E62A2A; }
  .step-row .text strong {
    display: block;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #1F1F1F;
    margin-bottom: 15px; }
  .step-row .text p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F; }

.docs-slider-container {
  overflow-x: hidden; }

.jobs-type-list-slider .swiper-pagination {
  display: none; }

@media (min-width: calc($tb + 1px)) and (max-width: 1380px) {
  .docs-slider .sld-nav-btn-prev, .docs-slider .sld-nav-btn-next {
    transform: translateX(0); } }

@media (max-width: 1024px) {
  .service-main {
    padding-top: 60px;
    padding-bottom: 60px; }
    .service-main .wrapper {
      padding-right: 0; }
  .intro-row .get-consult {
    margin-top: -60px; }
  .intro-row .text .title {
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 40px; }
  .intro-row {
    display: flex;
    justify-content: space-between;
    grid-column-gap: 0; }
    .intro-row .text {
      max-width: 512px;
      width: 100%;
      margin-right: 30px; }
  .intro-row {
    margin-bottom: 60px; }
  .jobs-types > .title {
    margin-bottom: 40px; }
  .jobs-types {
    padding-right: 60px; }
  .jobs-type-list strong {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px; }
  .jobs-type-list p {
    font-size: 14px;
    line-height: 20px; }
  .steps-section {
    padding-top: 60px;
    padding-bottom: 60px; }
  .step-row {
    grid-template-columns: 126px 1fr; }
  .step-row .text strong {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 15px; }
  .steps-list li:not(:last-child) {
    margin-bottom: 40px; } }

@media (max-width: 768px) {
  .service-main .wrapper {
    padding-right: 40px; }
  .intro-row {
    flex-direction: column; }
  .intro-row .get-consult {
    margin-top: 30px; }
  .intro-row .text {
    max-width: 100%;
    margin-right: 0; }
  .intro-row {
    margin-bottom: 40px; }
  .jobs-type-list {
    grid-template-columns: 100%;
    grid-row-gap: 40px; }
    .jobs-type-list li {
      display: flex; }
      .jobs-type-list li .ic {
        width: 48px;
        min-width: 48px;
        height: 48px;
        margin-right: 30px; }
  .jobs-types {
    padding-right: 0; }
  .step-row {
    grid-column-gap: 0; } }

@media (max-width: 577px) {
  .service-main .wrapper {
    padding-right: 20px; }
  .service-main {
    padding-top: 30px;
    padding-bottom: 36px; }
  .intro-row .text .title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px; }
  .intro-row .text p {
    font-size: 16px;
    line-height: 24px; }
  .intro-row .get-consult {
    margin-top: 40px; }
  .jobs-type-list-slider {
    padding-bottom: 44px; }
  .jobs-type-list {
    display: flex;
    grid-template-columns: none;
    grid-row-gap: 0;
    grid-column-gap: 0; }
  .jobs-type-list-slider .swiper-pagination {
    bottom: 0 !important; }
  .jobs-type-list li {
    flex-direction: column; }
  .jobs-types > .title {
    margin-bottom: 30px; }
  .jobs-type-list strong {
    font-size: 16px;
    line-height: 22px; }
  .steps-section {
    padding-top: 40px;
    padding-bottom: 40px; }
  .step-row {
    grid-template-columns: 100%;
    grid-row-gap: 20px; }
  .step-row .num {
    font-size: 60px;
    line-height: 40px; }
  .step-row .text p {
    font-size: 14px;
    line-height: 20px; }
  .jobs-type-list-slider .swiper-pagination {
    display: block; } }

.sertificates {
  padding-top: 80px;
  padding-bottom: 80px;
  background: #F5F6F6; }
  .sertificates .wrapper > .title {
    margin-bottom: 60px; }

.docs-slider {
  overflow: visible !important; }
  .docs-slider .sld-nav-btn {
    top: 44.5%; }

.docs-slider .swiper-slide {
  display: block;
  position: relative;
  max-width: 410px;
  width: 100%;
  background-color: #fff;
  padding-left: 30px;
  padding-right: 30px; }
  .docs-slider .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: contain; }

.file-type-lbl {
  position: absolute;
  top: 30px;
  left: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  padding: 4px 10px;
  background-color: #E62A2A; }

.docs-slider-container {
  position: relative; }
  .docs-slider-container:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: calc(var(--indentToViewport) - 30px);
    background: linear-gradient(270deg, #F5F6F6 0%, rgba(245, 246, 246, 0) 100%);
    z-index: 2; }
  .docs-slider-container:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: calc(var(--indentToViewport) - 30px);
    background: linear-gradient(270deg, #F5F6F6 0%, rgba(245, 246, 246, 0) 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    z-index: 2; }

@media (max-width: 1024px) {
  .docs-slider .sld-nav-btn-next {
    right: 0; }
  .docs-slider .sld-nav-btn-prev {
    left: 0; }
  .docs-slider .swiper-slide {
    max-width: 100%; }
  .docs-slider-container:after {
    width: calc(var(--indentToViewport) - 15px); }
  .docs-slider-container:before {
    width: calc(var(--indentToViewport)  - 15px); }
  .sertificates {
    padding-top: 60px;
    padding-bottom: 60px; }
  .sertificates .wrapper > .title {
    margin-bottom: 40px; }
  .file-type-lbl {
    padding: 4.26px 10.66px; } }

@media (max-width: 768px) {
  .docs-slider .swiper-slide {
    max-height: 407px; }
  .file-type-lbl {
    top: 25px;
    padding: 3.36px 8.15px;
    font-size: 13.0341px;
    line-height: 20px; } }

@media (max-width: 577px) {
  .sertificates {
    padding-top: 40px;
    padding-bottom: 40px; }
  .sertificates .wrapper > .title {
    margin-bottom: 20px; }
  .docs-slider {
    padding-bottom: 73px; }
  .docs-slider .sld-nav-btn-next {
    right: 0;
    top: auto;
    bottom: 0;
    transform: none; }
  .docs-slider .sld-nav-btn-prev {
    left: auto;
    right: 64px;
    top: auto;
    bottom: 0;
    transform: none; } }

.all-brands-page .page-title {
  margin-bottom: 60px; }

.brands-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px; }

.brand-card {
  padding: 40px;
  background: #F5F6F6;
  height: 100%; }

.brand-card .brand-card-head {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .brand-card .brand-card-head .brand-logo {
    width: 120px;
    height: 120px; }
    .brand-card .brand-card-head .brand-logo img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      mix-blend-mode: darken; }

.brand-card .brand-card-body {
  padding-top: 20px; }
  .brand-card .brand-card-body p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F; }
    .brand-card .brand-card-body p + p {
      margin-top: 20px; }
  .brand-card .brand-card-body ul {
    margin-top: 20px;
    margin-bottom: 20px; }
  .brand-card .brand-card-body ul li {
    position: relative;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
    padding-left: 37px; }
    .brand-card .brand-card-body ul li:not(:last-child) {
      margin-bottom: 10px; }
    .brand-card .brand-card-body ul li::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 5px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #E62A2A; }

@media (max-width: 1024px) {
  .all-brands-page .page-title {
    margin-bottom: 50px; }
  .brand-card {
    padding: 30px; }
  .brand-card .brand-card-head .brand-logo {
    width: 100px;
    height: 100px; } }

@media (max-width: 768px) {
  .brands-list {
    grid-template-columns: 100%;
    grid-row-gap: 10px; }
  .brands-list {
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-right: -40px; }
  .brand-card {
    padding: 20px 40px 30px 40px; }
  .all-brands-page {
    padding-bottom: 80px; } }

@media (max-width: 577px) {
  .brands-list {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px; }
  .brand-card {
    padding: 20px; }
    .brand-card .more-btn .ic {
      width: 48px;
      min-width: 48px;
      height: 48px; }
  .all-brands-page .page-title {
    margin-bottom: 30px; }
  .brand-card .brand-card-head .brand-logo {
    width: 80px;
    height: 80px; }
  .brand-card .brand-card-body p {
    font-size: 14px;
    line-height: 20px; }
  .brand-card .brand-card-body p + p {
    margin-top: 10px; }
  .all-brands-page {
    padding-bottom: 60px; }
  .brand-card .brand-card-body ul li {
    font-size: 14px;
    line-height: 20px;
    padding-left: 31px; } }

.brands-production-page .page-title {
  margin-bottom: 60px; }

.brand-production-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px; }

.brand-product-card {
  padding: 30px;
  background: #F5F6F6; }

.brand-product-card-head {
  display: grid;
  grid-template-columns: 180px 1fr;
  grid-column-gap: 30px; }

.brand-product-photo img {
  mix-blend-mode: darken; }

.brand-product-text {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.brand-product-text strong {
  display: block;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #1F1F1F;
  margin-bottom: 10px; }

.brand-product-text .cats {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #1F1F1F; }

.brand-product-card-body {
  padding-top: 20px; }

.brand-product-card-footer {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.brand-product-photo--tb {
  display: none; }

@media (min-width: 577px) and (max-width: 1024px) {
  .brand-product-photo--tb {
    display: block;
    height: 180px; }
    .brand-product-photo--tb img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      mix-blend-mode: darken; }
  .brand-product-card-head {
    grid-template-columns: 100%; }
  .brand-product-card-head .brand-product-photo {
    display: none; }
  .brand-product-card-content {
    display: grid;
    grid-template-columns: 180px 1fr;
    grid-column-gap: 30px; } }

@media (max-width: 1024px) {
  .brand-production-list {
    grid-template-columns: 100%; }
  .brands-production-page .page-title {
    margin-bottom: 50px; }
  .brand-product-text strong {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px; }
  .brand-product-text .cats {
    font-size: 16px;
    line-height: 22px; } }

@media (max-width: 768px) {
  .brand-production-list {
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-right: -40px;
    grid-row-gap: 10px; }
  .brand-product-card {
    padding: 30px 40px; }
  .brand-product-card .characteristics li span, .brand-product-card .characteristics li strong {
    font-size: 14px;
    line-height: 20px; }
  .brand-product-card-body {
    padding-top: 14px; }
  .brands-production-page {
    padding-bottom: 80px; } }

@media (max-width: 577px) {
  .brand-production-list {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px; }
  .brand-product-card-footer {
    justify-content: flex-end; }
  .brand-product-card-footer .accent-btn {
    display: none; }
  .brand-product-card-head {
    grid-template-columns: 120px 1fr;
    grid-column-gap: 12px; }
  .brand-product-card {
    padding: 20px; }
  .brands-production-page .page-title {
    margin-bottom: 30px; }
  .brand-product-text strong {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px; }
  .brand-product-text .cats {
    font-size: 14px;
    line-height: 18px; }
  .brand-product-text {
    justify-content: flex-start; }
  .brand-product-photo {
    margin-top: 4px; }
  .brand-product-card .characteristics li span, .brand-product-card .characteristics li strong {
    font-size: 12px;
    line-height: 16px; }
  .brand-product-card .more-btn .ic {
    width: 48px;
    min-width: 48px;
    height: 48px; }
  .brand-product-card-footer {
    margin-top: 9px; }
  .brands-production-page {
    padding-bottom: 60px; } }
