.service-centr {
    padding-top: 80px;
    padding-bottom: 40px;

    .wrapper > .title {
        margin-bottom: 30px;
    }
}

.text-content-2-cols  {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
}


@media (max-width: $tb) { 

    .service-centr {
        padding-top: 60px;
        padding-bottom: 30px;
    }

    .text-content .img-wrap {
        height: 458px;

        img {
            height: 100%;
        }
    }

    .text-content p + p {
        margin-top: 20px;
    }

}

@media (max-width: $mb) {


    .text-content-2-cols {
        grid-template-columns: 100%;
    }

    .text-content .img-wrap {
        height: auto;
    }

    .text-content-2-cols .col:last-child {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;

        .img-wrap {
            margin: 0;
            
            img {
                height: auto;
            }
        }
    }

    .text-content ul {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}


@media (max-width: $mx) {
    .service-centr {
        padding-top: 40px;
        padding-bottom: 30px;
    }

    .service-centr .wrapper > .title {
        margin-bottom: 20px;
    }

    .text-content-2-cols .col:last-child {
        display: flex;
        flex-direction: column;
    }

    .text-content-2-cols .col:last-child .img-wrap {
        order: 1;
        margin-bottom: 30px;
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
    }

    .text-content-2-cols .col:last-child  p {
        order: 2;
    }

    .text-content-2-cols .col:last-child .img-wrap img {
        height: 240px;
    }
}